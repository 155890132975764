import { RcFile } from 'antd/lib/upload'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { BASE_API_URL } from 'constant'
import i18n from 'layouts/i18n'
import _, { Dictionary, get, has, isArray, omit } from 'lodash'
import {
  AccountPayableLine as AccountPayableLineType,
  // ArrivalSlipImportSequence,
  ArrivalSlipLine,
  BillingToMasterData,
  CreateProductReturn,
  CreatePurchaseReturn,
  CreateReturn,
  EachProductReplenishmentSetting,
  FetchSlipNoData,
  FetchSlipNoProductLineData,
  Inventory,
  InventoryEvaluation,
  OrderPayment,
  OrderPaymentCreate,
  OrderPaymentLineCreate,
  OrganizationMasterData,
  PaymentLine,
  ProductDetailData,
  ReturnSearchRead,
  SearchRead,
  StockMoveCreateData,
  TradingExpenseData,
} from 'services/ApiCallTypes'
import Fetcher from 'services/Fetcher'
import { MAP_LANGUAGE } from 'services/language.service'
import { apiResponseWrapper } from 'v2source/services/api/apiUtils'

import { UpdateAccountPayableProps } from 'data/AccountPayable/useMutationAccountPayable'
import { UpdateAccountReceivableProps } from 'data/AccountReceivable/useMutationAccountReceivable'
import { CreateDisposal } from 'data/Disposal/useMutationDisposal'
import { ImplementationHistory } from 'data/useImplementationHistory'
import { UseProductData } from 'data/useProductCategory'

import { getLocalTimeZoneName, isError } from 'helpers/Utils'
import API_ROUTE from 'utils/constants/api.constant'
import { convertToNull } from 'utils/helpers'
import { BudgetItem } from 'views/BudgetCreationForecastControlKpiAnalysis/InitialSetting/CreateBudget/CreateBudget'
import { DataTypeMasterAdditionalCost } from 'views/BudgetCreationForecastControlKpiAnalysis/InitialSetting/MasterAdditionalCost/MasterAdditionalCost'
import { DataType } from 'views/BudgetCreationForecastControlKpiAnalysis/InitialSetting/MasterCost/MasterCost'
import { ListResourcePlan } from 'views/BudgetCreationForecastControlKpiAnalysis/InitialSetting/ResourcePlan/ResourcePlan'
import { DatatypeWebsiteSetting } from 'views/BudgetCreationForecastControlKpiAnalysis/InitialSetting/WebsiteSetting/WebsiteSetting'
import { CountryMasterData } from 'views/Common/CommonFeatures/CountryMaster/CountryMaster'
import {
  ArrivalSlipData,
  CreateArrivalSlip,
} from 'views/InventoryControl/ArrivalPurchaseProcess/ArrivalSlipInfoDetailCreate/types'
import { IShipmentPost } from 'views/InventoryControl/ShipmentProcess/CreateShipmentInstruction/CreateShipmentInstruction'
import { IShipmentConfirmationPost } from 'views/InventoryControl/ShipmentProcess/ShipmentConfirmationProcess'
import { WarehouseCategoryType as InHouseInventoryWarehouseQuantity } from 'views/MDExecution/Analysis/ProductInformationSearch/components/ProductInformationModal/InHouseInventoryQuantity/data/useProductInformationInHouseInventoryQuantity'
import {
  SummaryTableWithPicturesData,
  ParamsType as SummaryTableWithPicturesSchema,
} from 'views/MDExecution/Analysis/SummaryTableWithPictures/types'
import { IReplenishmentInstructionPlan } from 'views/MDExecution/ReplenishmentProcess/CreateReplenishmentInstruction/CreateReplenishmentInstruction'
import { IReplenishmentPost } from 'views/MDExecution/ReplenishmentProcess/EachProductReplenishmentInstructionSetting/EachProductReplenishmentInstructionSetting'
import { ISafetyStockPost } from 'views/MDExecution/ReplenishmentProcess/EachProductReplenishmentSetting/EachProductReplenishmentSetting'
import { IReplenishmentPlanPost } from 'views/MDExecution/ReplenishmentProcess/EachStoreReplenishmentSetting/EachStoreReplenishmentSetting'
import { IReplenishmentRequest } from 'views/MDExecution/ReplenishmentProcess/StoreReplenishmentRequest/StoreReplenishmentRequest'
import { UpdateSchemaType } from 'views/ProductionControl/FactoryVendorInformation/SupplierMasterRead/partials/updateSchema'
import { TSchemaValue } from 'views/ProductionControl/FactoryVendorInformation/SupplierMasterRegistration/schema'
import { IMaterial } from 'views/ProductionControl/MaterialInformation/ListMaterialInspection/schema'
import { GetCategoryPurchaseQuantitySettingParams } from 'views/PSAnalysisMDPlan/PurchasePlan/SettingNumberPurchasesEachCategory/services/getCategoryPurchaseQuantitySetting'

const transformResponse = (res: AxiosResponse<any>) =>
  (res.data.result && res.data.result.records) || []

class BaseApiCall {
  public api: AxiosInstance

  private currentLanguage: string

  constructor() {
    // this.api = Fetcher.createAuthAxios(BASE_API_URL)
    this.api = Fetcher.createAuthAxios(BASE_API_URL, 'token-ldx', 'key-pin')
    i18n.on('initialized', () => {
      this.currentLanguage = MAP_LANGUAGE[i18n?.language]
    })
    i18n.on('languageChanged', () => {
      this.currentLanguage = MAP_LANGUAGE[i18n?.language]
    })
  }

  private _create<T = Record<string, unknown>>(url: string, data: T) {
    return this.api.post(url, {
      params: {
        args: [data],
        kwargs: {
          context: { lang: this.currentLanguage },
        },
      },
    })
  }

  private _batchCreate<T = Record<string, unknown>>(model: string, data: T[]) {
    return this.api.post(`/dataset/${model}/duplicates`, {
      params: {
        args: [],
        kwargs: {
          context: { lang: this.currentLanguage },
          records: data,
        },
      },
    })
  }

  private _update<T = Record<string, unknown>>(
    url: string,
    { id, data }: { id: number; data: T },
  ) {
    return this.api.post(url, {
      params: {
        args: [id, convertToNull(data)],
        kwargs: {
          context: { lang: this.currentLanguage },
        },
      },
    })
  }

  private async _search<T = any>(
    url: string,
    params: SearchRead,
  ): Promise<ReactQueryResponse<T>> {
    try {
      const res = await this.api
        .post(url, {
          params: {
            sort: 'id desc',
            ...omit(params, ['kwargs']),
            context: {
              lang: this.currentLanguage,
              ...get(params, 'context', {}),
            },
          },
          kwargs: {
            ...(params?.kwargs || {}),
            context: {
              lang: this.currentLanguage,
              ...(params.kwargs?.context || {}),
            },
          },
        })
        .then((res) => res.data)

      if (has(res, 'error')) {
        throw new Error(res.error.data.message)
      }
      return res
    } catch (error) {
      // console.log('ERROR SEARCH READ: ', url, error.message)
    }
  }

  private async _read<T = any>(
    url: string,
    id: number | number[],
    params: ReadParams = {
      fields: [],
      kwargs: {
        context: { lang: this.currentLanguage },
      },
    },
  ): Promise<ReadResponse<T>> {
    try {
      const res = await this.api
        .post(url, {
          params: {
            args: [id, params.fields],
            kwargs: {
              context: { lang: this.currentLanguage },
              ...params.kwargs,
            },
          },
        })
        .then((res) => res.data)

      if (has(res, 'error')) {
        throw new Error(res.error.message)
      }
      return res
    } catch (error) {
      console.log('ERROR READ: ', error)
    }
  }

  _generateCrud<T = any>(model: string) {
    return {
      search: async <Response = T>(params?: SearchParams) => {
        const res = await this._search<Response>(
          `/dataset/${model}/search_read/`,
          params,
        )
        return res?.result
      },
      get: <Response = T>(id: number | number[], params?: ReadParams) =>
        this._read<Response>(`/dataset/${model}/read/`, id, params),
      write: async <Response = ResponseCreate | ResponseError>(
        id: number | number[],
        data: T,
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/write/`,
          {
            params: {
              args: [id, data],
              kwargs: {
                ...kwargs,
                context: {
                  ...kwargs.context,
                  lang: this.currentLanguage,
                },
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate).result
      },
      create: async <Data = any, Response = ResponseCreate | ResponseError>(
        data: Data,
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/create/`,
          {
            params: {
              args: [data],
              kwargs: {
                ...kwargs,
                context: {
                  ...kwargs.context,
                  lang: this.currentLanguage,
                },
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate).result
      },
      readImageProduct: async <
        // Data = any,
        Id = number,
        Response = ResponseCreate | ResponseError,
      >(
        id: Id,
        // data: Data,
        // kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/product.specification.image/search_read/`,
          {
            params: {
              args: [],
              kwargs: {
                context: { lang: this.currentLanguage },
              },
              domain: [['id', '=', id]],
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as any).result
      },
      unlink: async <Response = any>(
        ids: number | number[],
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/unlink/`,
          {
            params: {
              args: [ids],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }
        return (res?.data as unknown as ResponseCreate).result
      },
      soft_unlink: async (
        ids: number | number[],
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/soft_unlink/`,
          {
            params: {
              args: Array.isArray(ids) ? ids : [ids],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }
        return true
      },
      unarchives: async (ids: number[], kwargs: Record<string, any> = {}) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/unarchives/`,
          {
            params: {
              args: [...ids],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }
        return true
      },
      upload: (id: number, key: string, file: File | RcFile) => {
        const formData = new FormData()
        formData.append(key, file)
        return this.api.post(`dataset/${model}/${id}/upload_files`, formData)
      },

      /**
       * Archive using default odoo fn
       *
       * @param ids product id
       * @param kwargs
       * @returns
       */
      archive: async (
        ids: number | number[],
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/action_archive/`,
          {
            params: {
              args: Array.isArray(ids) ? [ids] : [[ids]],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }
        return true
      },

      /**
       * Unarchive using default odoo fn
       *
       * @param ids product id
       * @param kwargs
       * @returns
       */
      unarchive: async (
        ids: number | number[],
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/${model}/action_unarchive/`,
          {
            params: {
              args: Array.isArray(ids) ? ids : [ids],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }
        return true
      },
      download_excel: <T = any>(
        { filename, ...params }: ApiService.ExcelDownloadRequestParams<T>,
        options?: ApiService.DefaultRequestConfig,
      ): any => {
        return apiResponseWrapper.Blob(
          this.api.post(
            `/dataset/${model}/download_excel`,
            {
              params: {
                args: [],
                kwargs: {
                  lang: this.currentLanguage,
                  tz: getLocalTimeZoneName(),
                  ...params,
                },
              },
            },
            {
              responseType: 'blob',
              ...(options || {}),
            },
          ),
          { filename, fileType: 'application/vnd.ms-excel' },
        )
      },
    }
  }

  login(data) {
    const formData = new FormData()
    Object.entries(data).forEach((item) => {
      const [key, value] = item as any
      formData.append(key, value)
    })
    return this.api.post(`/token_auth/`, formData)
  }

  zendeskLogin(data) {
    const formData = new FormData()
    Object.entries(data).forEach((item) => {
      const [key, value] = item as any
      formData.append(key, value)
    })
    return this.api.post(`/zendesk_auth/`, formData)
  }

  ProductDisplayPatterns = {
    update: (id: number | string, data: any) => {
      // const formData = new FormData()
      // Object.entries(data).forEach((item) => {
      //   const [key, value] = item as any
      //   formData.append(key, value)
      // })
      return this.api.patch(API_ROUTE.PRODUCT_DISPLAY_PATTERN.WRITE, {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    post: (data: any) => {
      // const formData = new FormData()
      // Object.entries(data).forEach((item) => {
      //   const [key, value] = item as any
      //   formData.append(key, value)
      // })

      return this.api.post(API_ROUTE.PRODUCT_DISPLAY_PATTERN.CREATE, {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  WarehouseDisplayPatterns = {
    update: (id: number | string, data: any) => {
      return this.api.post(API_ROUTE.WAREHOUSE_DISPLAY_PATTERN.WRITE, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    create: (data: any) => {
      return this.api.post(API_ROUTE.WAREHOUSE_DISPLAY_PATTERN.CREATE, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    unlink: (id: number) => {
      return this.api.post(API_ROUTE.WAREHOUSE_DISPLAY_PATTERN.UNLINK, {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  MDProductDisplayPatterns = {
    update: (id: number | string, data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post(`/dataset/md.display.pattern/write/`, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number | number[]) => {
      return this.api.post(`/dataset/md.display.pattern/unlink/`, {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
    post: (data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })

      return this.api.post(`/dataset/md.display.pattern/create/`, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
  }

  ProductImportSequences = {
    update: (id: number | string, data: any) => {
      return this.api.patch(`/dataset/product.import.sequence/write/`, {
        params: {
          args: [Number(id), data],

          kwargs: {},
        },
      })
    },
    post: (data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })

      return this.api.post(`/dataset/product.import.sequence/create`, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/product.import.sequence/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  SpecificationMenu = {
    upload: (id, type, file) => {
      const formData = new FormData()
      formData.append('image', file)
      return this.api.put(`/products/${id}/specifications/${type}`, formData)
    },
    uploadDocument: (id, file) => {
      const formData = new FormData()
      formData.append('document_reference_file', file)
      return this.api.post(
        `/dataset/product_specification/${id}/upload_files`,
        formData,
      )
    },
    put: (id: any, data: any) => {
      return this.api.post(`/dataset/product.template/write/`, {
        params: {
          args: [Number(id), data],

          kwargs: {},
        },
      })
    },
    deleteImage: (id: any) => {
      return this.api.delete(`/products/specification_images/${id}`)
    },
    createComment: (id: any, data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        if (value) {
          if (isArray(value)) {
            for (let i = 0; i < value?.length; i += 1) {
              formData.append(key, value[i])
            }
          } else {
            formData.append(key, value)
          }
        }
      })
      return this.api.post(`/products/${id}/specifications/comments`, formData)
    },
    saveMd: (id: any, data: any) => {
      return this.api.post('/dataset/product.template/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    cancelMd: (id: any) => {
      return this.api.post('/dataset/product.template/cancel_temporary_data', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    confirmMd: (id: any, data: any) => {
      return this.api.post(
        '/dataset/product.template/confirm_write_to_product_master',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    downloadSpecification: (ids: number[]) => {
      return this.api.post(
        '/specification/download/bulk',
        {
          specification_ids: ids,
        },
        {
          headers: {
            'Content-Type': 'text/plain',
          },
          responseType: 'arraybuffer',
        },
      )
    },
  }

  RequestContent = {
    create: (name: string) => {
      return this.api.post('/dataset/mail.message.content/create', {
        params: {
          args: [
            {
              name,
            },
          ],

          kwargs: {},
        },
      })
    },
    update: (id: number, name: string) => {
      return this.api.patch('/dataset/mail.message.content/update', {
        params: {
          args: [
            id,
            {
              name,
            },
          ],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/mail.message.content/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  OrderQuotation = {
    ...this._generateCrud('order.master'),
    MaterialLine: this._generateCrud('order.material.line'),
    readMaterialLine: (ids: number[], fields: string[]) =>
      this.api.post(`${BASE_API_URL}/dataset/order.material.line/read/`, {
        params: {
          args: [ids, fields],
          kwargs: {},
        },
      }),
    post: (data) => {
      return this.api.post(`/dataset/order.master/create`, {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    createOrderInfo: ({ id, data }: { id: number; data: any }) => {
      return this.api.post(`/dataset/receipt.order/create_order`, {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post(`/dataset/order.master/write`, {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    markQuotation: (id) => {
      return this.api.post('/dataset/order.master/action_mark_quotation', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    markTemporary: (id) => {
      return this.api.post('/dataset/order.master/action_mark_temporary', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    markFinal: (id) => {
      return this.api.post('/dataset/order.master/action_mark_final', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    markPass: (id) => {
      return this.api.post('/dataset/order.master/action_order_pass', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (ids: number[]) => {
      return this.api.post('/dataset/order.master/soft_unlink', {
        params: {
          args: ids,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/order.master/unarchives/', {
        params: {
          args: ids,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    createOwnCompany: (id) => {
      return this.api.post(
        '/dataset/order.master/action_new_risk_company_order',
        {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    batchCreate: (data) => {
      return this._batchCreate('order.master', data)
    },
    productLine: this._generateCrud('order.product.line'),
    orderBacklogSummary: async (params: SearchParams) => {
      const res = await this.api
        .post('/dataset/order.master/backlog_summary', {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
      return res?.result
    },
  }

  Quotation = {
    ...this._generateCrud('quotation.master'),
    readMaterialLine: (ids: number[], fields: string[]) =>
      this.api.post(`${BASE_API_URL}/dataset/quotation.material.line/read/`, {
        params: {
          args: [ids, fields],
          kwargs: {},
        },
      }),
    post: (data) => {
      return this.api.post(`/dataset/quotation.master/create`, {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post(`/dataset/quotation.master/write`, {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    createOrder: (id) => {
      return this.api.post('/dataset/quotation.master/create_order', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    markTemporary: (id) => {
      return this.api.post(
        '/dataset/quotation.master/register_temporary_quotation',
        {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    markFinal: (id) => {
      return this.api.post('/dataset/quotation.master/quotation_confirm', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    batchCreate: (data) => {
      return this._batchCreate('quotation.master', data)
    },
  }

  BatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/products/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  OrderBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(`/orders/insert_batch_registration/${id}`, formData)
    },
  }

  QuotationBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/quotations/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  Warehouses = {
    ...this._generateCrud('stock.warehouse'),
    confirmReturnInstruction: (ids: number[]) => {
      return this.api.post('/dataset/warehouse.return_instruction/confirm', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    confirmDisposalInstruction: (ids: number[]) => {
      return this.api.post('/dataset/stock.scrap.slip/confirm', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    deleteDisposal: (ids: number[]) => {
      return this.api.post('/dataset/stock.scrap.slip/unlink', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    returnInstructionRegis: (data: any) => {
      return this.api.post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.CREATE, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    returnInstructionUpdate: (id: number, data: any) => {
      return this.api.post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.WRITE, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    disposalInstructionRegis: (data: any) => {
      return this.api.post('/dataset/stock.scrap.slip/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    disposalInstructionUpdate: (id: number, data: any) => {
      return this.api.post('/dataset/stock.scrap.slip/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (ids: number[]) => {
      return this.api.post('/dataset/stock.warehouse/soft_unlink', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/stock.warehouse/unarchives/', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
    changeDataForAllWarehouse: (payload: Record<string, any>) => {
      return this.api.post('/dataset/stock.warehouse/change_all_warehouse', {
        params: {
          args: [payload],
          kwargs: {},
        },
      })
    },
  }

  Materials = {
    create: (data: IMaterial) => {
      return this.api.post('/materials', data)
    },
  }

  Products = {
    createComment: (id: string, data: any) => {
      return this.api.post(`/products/${id}/specifications/comments`, data)
    },
  }

  Factories = {
    search: (data: any) => {
      return this.api.post(`/dataset/factory.master/search_read/`, {
        params: data,
      })
    },

    create: (data: any) => {
      return this.api.post('/factories', data)
    },
  }

  StoreCashierImportSequence = {
    create: (data: any) => {
      return this.api.post('/dataset/store.cashier.import.sequence/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/store.cashier.import.sequence/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/store.cashier.import.sequence/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  StoreAllocationTemplateAnalysis = {
    create: (data: any) => {
      return this.api.post(
        '/dataset/store.allocation.template.analysis/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  FactoryMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/res.partner/search_read/', {
        ...params,
        domain: [...params?.domain, ['partner_classification', '=', 'factory']],
      })
    },
    read: (id: number, params?: ReadParams) => {
      return this._read('/dataset/res.partner/search_read/', id, params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/res.partner/create/', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/res.partner/write/', {
        params: {
          args: [id, data],
          kwargs: {},
        },
      })
    },
    unlink: (id: number[]) => {
      return this.api.post('/dataset/res.partner/unlink', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
    softDelete: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/soft_unlink', {
        params: {
          args: ids,
          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/unarchives/', {
        params: {
          args: ids,
          kwargs: {},
        },
      })
    },

    Files: this._generateCrud('factory.document'),
    upload_file: ({
      factoryId,
      file,
    }: {
      factoryId: number
      file: File | RcFile
    }) => {
      const formData = new FormData()
      formData.append('document_reference_file', file)

      return this.api.post(
        `/dataset/factory.document/${factoryId}/upload_files`,
        formData,
      )
    },
  }

  FactoryScore = {
    read: async (index: number, ids: Array<number>) => {
      const response = await this.api.post('/dataset/factory.score/read', {
        params: {
          args: [ids, ['question_id', 'score']],

          kwargs: {},
        },
      })

      return { index, response }
    },
    create: (id, data) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          // lets assume this is response from backend
          const response = {
            message: 'any message',
            data,
          }
          resolve(response)
        }, 2000)
      })
    },
  }

  FactoryQuestion = {
    ...this._generateCrud('factory.score.question'),
  }

  SupplierQuestion = {
    create: (data: any) => {
      return this.api.post('/dataset/supplier.score.question/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id: number, data: any) => {
      return this.api.post('/dataset/supplier.score.question/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    unlink: (id: string) => {
      return this.api.post('/dataset/supplier.score.question/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  SupplierMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/res.partner/search_read/', {
        ...params,
        domain: [
          ...(params.domain || []),
          ['partner_classification', '=', 'supplier'], // * Required to differentiate with other partners
        ],
      })
    },
    create: async (data: TSchemaValue) => {
      return this.api.post('/dataset/res.partner/create/', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    update: (data: UpdateSchemaType, id: number) => {
      return this.api.put('/dataset/res.partner/write/', {
        params: {
          args: [id, data],
          kwargs: {},
        },
      })
    },
    delete: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/soft_unlink/', {
        params: {
          args: ids,
          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/unarchives/', {
        params: {
          args: ids,
          kwargs: {},
        },
      })
    },
  }

  CalendarMaster = {
    FiscalYear: this._generateCrud('account.fiscal.year'),
    Weeks: this._generateCrud('calendar.calendar'),
    Holiday: this._generateCrud('calendar.holiday'),
    Master: this._generateCrud('calendar.master'),

    getFiscalYearFromDate: ({ input_date }: { input_date: string }) => {
      return this.api.post('/dataset/account.fiscal.year/search_read', {
        params: {
          domain: [
            ['date_from', '<=', input_date],
            ['date_to', '>=', input_date],
          ],
          fields: ['fiscal_year', 'date_from', 'date_to'],
        },
      })
    },
    generateFiscalYear: async (data: any) => {
      const response = await this.api.post(
        API_ROUTE.CALENDAR_MASTER.GENERATE_CALENDAR,
        {
          params: {
            args: [data],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
      return response
    },
    writeAdjustPeriode: async (data: any) => {
      const response = await this.api.post(
        API_ROUTE.CALENDAR_MASTER.WRITE_DATES,
        {
          params: {
            args: [data],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
      return response
    },
  }

  ProductMaster = {
    ...this._generateCrud('product.template'),
    create: async (data: any, _extra: { _lang?: string } = {}) => {
      const response = await this.api.post('/dataset/product.template/create', {
        params: {
          args: [omit(data, ['exclude_color_size_combination'])],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              exclude_color_size_combination:
                data.exclude_color_size_combination,
            },
          },
        },
      })

      if (_.has(response.data, 'error')) {
        throw Error(response.data?.error?.data?.message ?? 'Error!')
      }

      return response.data
    },
    write: async (id: number, data: any) => {
      const response = await this.api.post('/dataset/product.template/write', {
        params: {
          args: [[id], omit(data, ['exclude_color_size_combination'])],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              exclude_color_size_combination:
                data.exclude_color_size_combination,
            },
          },
        },
      })

      if (_.has(response.data, 'error')) {
        throw Error('Error')
      }
    },
    createMaterial: async (data: any) => {
      const response = await this.api.post(
        '/dataset/product.template/create/',
        {
          params: {
            args: [
              {
                ...data,
                type: 'material',
              },
            ],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      return response
    },
    updateMaterial: async (id: number, data: any) => {
      const response = await this.api.post('/dataset/product.product/write/', {
        params: {
          args: [
            id,
            {
              ...data,
              type: 'material',
            },
          ],
          kwargs: {},
        },
      })

      return response
    },
    searchMaterial: async (domain: any) => {
      const response = await this.api.post(
        '/dataset/product.template/search_read/',
        {
          params: {
            domain: [['type', '=', 'material'], ...domain],
          },
        },
      )

      if (has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
    searchProduct: (domain: any, { fields }: { fields?: string[] } = {}) => {
      return this.api.post('/dataset/product.template/search_read', {
        params: {
          domain,
          fields,
        },
      })
    },

    uploadMaterial: (id: number, file: any) => {
      const formData = new FormData()
      formData.append('image', file)
      return this.api.put(
        `/products/${id}/specifications/product_images`,
        formData,
      )
    },

    deleteMaterial: (id: number[]) => {
      return this.api.post('/dataset/product.template/unlink', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },

    softDeleteMaterial: (id: number[]) => {
      return this.api.post('/dataset/product.template/soft_unlink', {
        params: {
          args: id,
          kwargs: {},
        },
      })
    },

    restoreDeleteMaterial: (id: number[]) => {
      return this.api.post('/dataset/product.template/unarchives/', {
        params: {
          args: id,
          kwargs: {},
        },
      })
    },

    deleteProduct: (id: number[]) => {
      return this.api.post('/dataset/product.template/unlink', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },

    softDeleteProduct: (id: number[]) => {
      return this.api.post('/dataset/product.template/action_archive', {
        params: {
          args: id,

          kwargs: {},
        },
      })
    },

    restoreDeleteProduct: (id: number[]) => {
      return this.api.post('/dataset/product.template/action_unarchive/', {
        params: {
          args: id,

          kwargs: {},
        },
      })
    },

    upload_image: (productId: number, imageFile: string) => {
      const formData = new FormData()
      formData.append('image', imageFile)
      return this.api.post(
        `/products/${productId}/specifications/product_images/`,
        formData,
      )
    },
  }

  Product = {
    ...this._generateCrud('product.product'),
    create: async (
      data: any,
      _extra: { context?: Record<string, any> } = {},
    ) => {
      const response = await this.api.post('/dataset/product.product/create', {
        params: {
          args: [omit(data, ['exclude_color_size_combination'])],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              exclude_color_size_combination:
                data.exclude_color_size_combination,
              ...(_extra?.context ?? {}),
            },
          },
        },
      })
      if (isError(response?.data)) {
        throw new Error(response?.data.error.data.message)
      }
      return response.data?.result
    },
    write: async (
      id: number,
      data: any,
      _extra: { context?: Record<string, any> } = {},
    ) => {
      const response = await this.api.post('/dataset/product.product/write', {
        params: {
          args: [[id], omit(data, ['exclude_color_size_combination'])],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              exclude_color_size_combination:
                data.exclude_color_size_combination,
              ...(_extra?.context ?? {}),
            },
          },
        },
      })

      if (isError(response?.data)) {
        throw new Error(response?.data.error.data.message)
      }
      return response.data?.result
    },
    Composition: {
      ...this._generateCrud('product.composition'),
      Mix: this._generateCrud('product.composition.mix'),
    },
    ManufacturingOrder: this._generateCrud('manufacturing.order'),
    generateMaterialOrder: (data: any) => {
      return this.api.post('/dataset/order.master/generate_material_order', {
        params: {
          args: [],
          kwargs: {
            payload: data,
          },
        },
      })
    },
    searchWithInventory: <T = any>(params?: SearchParams) => {
      return this.api
        .post<ReactQueryResponse<T>>(
          API_ROUTE.PRODUCT_PRODUCT.SEARCH_READ_WITH_INVENTORY,
          {
            params: {
              args: [],
              kwargs: params,
            },
          },
        )
        .then((res) => res.data.result)
    },
    searchByMaterials: (material_ids: number[], fields: string[] = []) => {
      return this.api
        .post<ReactQueryResponse<any>>(
          API_ROUTE.PRODUCT_PRODUCT.SEARCH_PRODUCT_BY_MATERIAL,
          {
            params: {
              args: [],
              kwargs: {
                material_ids,
                fields,
              },
            },
          },
        )
        .then((res) => res.data.result)
    },
    searchByProducts: (product_ids: number[], fields: string[] = []) => {
      return this.api
        .post<ReactQueryResponse<any>>(
          API_ROUTE.PRODUCT_PRODUCT.SEARCH_MATERIAL_BY_PRODUCT,
          {
            params: {
              args: [],
              kwargs: {
                product_ids,
                fields,
              },
            },
          },
        )
        .then((res) => res.data.result)
    },
    writeMultiple: (args: any[]) => {
      return this.api.post(API_ROUTE.PRODUCT_PRODUCT.WRITE_MULTIPLE, {
        params: {
          args,
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    getDropdownData: (material_ids: number[]) => {
      return this.api
        .post(API_ROUTE.PRODUCT_PRODUCT.GET_DROPDOWN_DATA, {
          params: {
            args: [],
            kwargs: {
              material_ids,
            },
          },
        })
        .then((res) => res.data.result)
    },
    productDataDetail: (product_ids: number[], store_ids: number[] = []) => {
      return this.api
        .post<{ result: ProductDetailData[] }>(
          API_ROUTE.PRODUCT_PRODUCT.PRODUCT_DATA_DETAIL,
          {
            params: {
              args: [],
              kwargs: {
                product_ids,
                store_ids,
              },
            },
          },
        )
        .then((res) => res.data.result)
    },
  }

  ProductImage = this._generateCrud('product.specification.image')

  ColorMaster = {
    ...this._generateCrud('color.master'),
    create: (data: any) => {
      return this.api.post('/dataset/color.master/create/', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    update: (id: any, data: any) => {
      return this.api.patch(`/dataset/color.master/write/`, {
        params: {
          args: [Number(id), data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/color.master/unlink', {
        params: {
          args: [[id]],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    read: <T>(colorId: number) => {
      return this.api.post<any, AxiosResponse<ReadResponse<T>>>(
        '/dataset/color.master/read/',
        {
          params: {
            args: [colorId],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    Preset: this._generateCrud('color.preset'),
    ColorGroupPattern: this._generateCrud('color.group.pattern'),

    /**
     * @deprecated
     * Use ColorMaster.Preset.search instead
     * @param params
     * @returns
     */
    getColorPreset: (params?: SearchParams) => {
      return this._search('/dataset/color.preset/search_read/', params)
    },
  }

  BillingMethodMaster = {
    ...this._generateCrud('billing.method'),
    create: (data: any) => {
      return this.api.post('/dataset/billing.method/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (data: any) => {
      return this.api.post('dataset/billing.method/write', {
        params: {
          args: data,

          kwargs: {},
        },
      })
    },

    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/billing.method/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  SizeMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/size.master/search_read/', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/size.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    update: (data: any) => {
      return this.api.post('dataset/size.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/size.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    Preset: this._generateCrud('size.preset'),

    /**
     * @deprecated
     * Use SizeMaster.Preset.search instead
     * @param params
     * @returns
     */
    getSizePreset: (params?: SearchParams) => {
      return this._search('/dataset/size.preset/search_read/', params)
    },
  }

  BrandMaster = {
    ...this._generateCrud('brand.master'),
    search: (params?: SearchParams) => {
      return this._search('/dataset/brand.master/search_read/', params)
    },

    create: (data: any) => {
      return this.api.post('/dataset/brand.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    update: (data: any) => {
      return this.api.post('dataset/brand.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/brand.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  CountryMaster = {
    ...this._generateCrud('res.country'),
    create: async (data: CountryMasterData) => {
      const response = await this.api.post('/dataset/res.country/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    unlink: async (id: number) => {
      const response = await this.api.post(
        '/dataset/res.country/set_inactive',
        {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    restore: async (id: number) => {
      const response = await this.api.post('/dataset/res.country/set_active', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/res.country/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  ProductTypeMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/type.master/search_read', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/type.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/type.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/type.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductClassificationMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/classification.master/search_read', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/classification.master/unlink', {
        params: {
          args: [[data]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/classification.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ItemMaster = {
    ...this._generateCrud('item.master'),
    search: (params?: SearchParams) => {
      return this._search('/dataset/item.master/search_read/', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/item.master/create/', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/item.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/item.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductLargeClassification = {
    create: (data: UseProductData) => {
      return this.api.post('/dataset/large_classification/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/large_classification/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/large_classification/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  ProductMediumClassification = {
    create: (data: UseProductData) => {
      return this.api.post('/dataset/medium_classification/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/medium_classification/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/medium_classification/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  ProductSmallClassification = {
    create: (data: UseProductData) => {
      return this.api.post('/dataset/small_classification/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/small_classification/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/small_classification/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  StoreSales = {
    ...this._generateCrud('store.sales'),
    create: (data: any) => {
      return this.api.post('/dataset/store.sales/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    export_excel: (data: any) => {
      return this.api
        .post(
          '/dataset/store.sales/export_excel_store_sales/',
          {
            params: {
              args: [data],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
        .then((res) => res.data)
    },
    calculate_purchase_analysis: (data: any) => {
      return this.api.post(API_ROUTE.STORE_SALES.CALCULATE_PURCHASE_ANALYSIS, {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    download_purchase_analysis: (data: any) => {
      return this.api.post(API_ROUTE.STORE_SALES.DOWNLOAD_PURCHASE_ANALYSIS, {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  StoreSalesReturn = {
    create: (data: any) => {
      return this.api.post('/dataset/store.sales/create_sales_return', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    export_excel: (data: any) => {
      return this.api
        .post(
          '/dataset/store.sales/export_excel_store_sales/',
          {
            params: {
              args: [data],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
        .then((res) => res.data)
    },
  }

  StoreSalesLine = {
    create: (data: any) => {
      return this.api.post('/dataset/store.sales.line/create/', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    write: (id: number, data: any) => {
      return this.api.post('/dataset/store.sales.line/write/', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    unlink: (id: number) => {
      return this.api.post('/dataset/store.sales.line/unlink/', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  OrderSheet = {
    ...this._generateCrud('order.sheet'),
    create: (ids: number[], is_temporary?: boolean) => {
      const order_ids = ids.map((x) => [4, x, 0])
      return this.api.post('/dataset/order.sheet/create', {
        params: {
          args: [
            {
              order_ids,
              is_temporary,
            },
          ],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    getTemporaryOSFileList: (orderSheetId: number) => {
      return this.api
        .post('/dataset/order.sheet/get_temporary_os_file_list', {
          params: {
            args: [
              {
                domain: [['id', '=', orderSheetId]],
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
  }

  StockTakingDate = {
    ...this._generateCrud('stock.taking.date.setting'),
  }

  StockTakingBatchRegistration = {
    create: (data: any) => {
      return this.api.post('/dataset/stock.taking.import.sequence/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post('/dataset/stock.taking.import.sequence/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    uploadExcel: (data: any) => {
      return this.api.post('/stock_taking/batch_registration', data)
    },
    delete: (id: number) => {
      return this.api.post('/dataset/stock.taking.import.sequence/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  StockMove = {
    search: async (params?: SearchRead) => {
      return this._search('/dataset/stock.move/search_read/', params)
    },
    custom_search: async (params?: SearchRead) => {
      return this._search('/dataset/stock.move/custom_search_read/', params)
    },
    get: (id: string | number) => {
      return this.api
        .post<{ result: OrderPayment[] }>('/dataset/stock.move/read/', {
          params: {
            args: [Number(id)],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data.result && res.data.result[0])
    },
    sendNotification: (data: any) => {
      return this.api.post('/dataset/stock.move/action_send_notification', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    confirmReceivePlan: (data: any) => {
      return this.api.post('/dataset/stock.move/confirm_receive_plan', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    confirm: (id: number | string, data: any) => {
      return this.api.post('/dataset/stock.move/confirm', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    write: async (id: number | string, data: Partial<StockMoveCreateData>) => {
      const response = await this.api.post('/dataset/stock.move/write/', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })

      return response
    },

    create: async (data: Partial<StockMoveCreateData>) => {
      const response = await this.api.post('/dataset/stock.move/create/', {
        params: {
          args: [data],

          kwargs: {},
        },
      })

      return response
    },

    readMoveList: async (data) => {
      const response = await this.api.post(
        '/dataset/stock.move/read_move_list/',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      return response
    },

    Line: {
      ...this._generateCrud('stock.move.line'),
      readMoveList: async (data) => {
        const response = await this.api.post(
          '/dataset/stock.move.line/read_move_list_v2',
          {
            params: {
              args: [data],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )

        return response
      },
    },
  }

  ReceivePlanResponsible = {
    create: (data: any) => {
      return this.api.post(
        '/dataset/warehouse.receive.plan.responsible/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    unlink: (id: string) => {
      return this.api.post(
        '/dataset/warehouse.receive.plan.responsible/unlink',
        {
          params: {
            args: [[id]],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  CustomClearance = {
    ...this._generateCrud('custom.clearance'),
    register: async (
      data: any,
      // invoiceFile: any,
      // packingListFile: any,
      // othersFile: any,
    ) => {
      const formData = new FormData()
      formData.append(
        'production_control_ids',
        JSON.stringify(data.production_control_ids),
      )
      formData.append('forwarder_id', data.forwarder_id)
      formData.append('complete_date', data.complete_date)
      // formData.append('invoice_file', invoiceFile)
      // formData.append('packing_list_file', packingListFile)
      // formData.append('other_document_file', othersFile)

      const response = await this.api.post(
        '/dataset/custom.clearance/register',
        formData,
      )

      if (_.has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
    complete: (id: any) => {
      return this.api.post('/dataset/custom.clearance/action_mark_complete', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    cancel: (id: any) => {
      return this.api.post('/dataset/custom.clearance/action_cancel_complete', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    write: async (id: number, data: any) => {
      const response = await this.api.post('/dataset/custom.clearance/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })

      if (_.has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
    updateFile: (id, { invoiceFile, packingListFile, othersFile }) => {
      const formData = new FormData()
      formData.append('invoice_file', invoiceFile)
      formData.append('packing_list_file', packingListFile)
      formData.append('other_document_file', othersFile)

      return this.api.put(
        `/dataset/custom.clearance/${id}/update_files`,
        formData,
      )
    },
    expenseRegister: async (id: any, data: any) => {
      const response = await this.api.post(
        '/dataset/custom.clearance/action_complete',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      if (has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
  }

  CustomClearanceNotice = {
    register: async (data: any) => {
      const response = await this.api.post(
        '/dataset/custom_clearance.notice/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      if (has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
  }

  OrderReceipt = {
    ...this._generateCrud('receipt.order'),
    search: async ({ values, limit = 13, offset = 0, domain = [] }) => {
      const _domain = [...domain]

      if (values?.product_name) {
        _domain.push(['product_name', 'ilike', values?.product_name])
      }
      if (values?.product_code) {
        _domain.push(['product_code', 'ilike', values?.product_code])
      }
      if (values?.order_receipt_no) {
        _domain.push(['order_receipt_no', '=', values?.order_receipt_no])
      }
      if (values?.order_receipt_customer_id) {
        _domain.push([
          'order_receipt_customer_id',
          '=',
          values?.order_receipt_customer_id,
        ])
      }
      if (values?.state) {
        _domain.push(['state', '=', values?.state])
      }

      return this.api.post('/dataset/receipt.order/search_read', {
        params: {
          domain: _domain,

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
          limit,
          offset,
        },
      })
    },
    create: (data: any) => {
      return this.api.post('/dataset/receipt.order/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    createLine: (data: any) => {
      return this.api.post('/dataset/receipt.order.product.line/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    Line: this._generateCrud('receipt.order.product.line'),

    write: (id: number, data: any, skipClosingDate = false) => {
      return this.api.post('/dataset/receipt.order/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: {
              is_skip_closing_date_check: skipClosingDate,
            },
          },
        },
      })
    },
    creditCheck: (value: number, customer_id: number) => {
      return this.api.post('/dataset/receipt.order/check_credit', {
        params: {
          args: [customer_id, value],

          kwargs: {},
        },
      })
    },
    generateBatchShipment: ({ data }) => {
      return this.api.post('/dataset/receipt.order/generate_batch_shipment', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    generateProductLinePartialShipment: ({ payload }) => {
      return this.api.post(
        '/dataset/receipt.order.product.line/create_partial_shipment',
        {
          params: {
            args: [],
            kwargs: {
              payload,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  OrderReceiptBatch = {
    create: async (values: any) => {
      const formData = new FormData()
      Object.entries(values).forEach(([key, item]) => {
        formData.append(key, item as any)
      })

      const response = await this.api.post(
        '/dataset/receipt.order.import.sequence/create',
        {
          params: {
            args: [values],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      if (has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
    update: async (id: any, values: any) => {
      const response = await this.api.post(
        `/dataset/receipt.order.import.sequence/write/`,
        {
          params: {
            args: [id, values],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      if (has(response.data, 'error')) {
        throw new Error('Error!')
      }

      return response
    },
    upload: async (file: any, id: any) => {
      const formData = new FormData()
      formData.append('file', file)

      return this.api.post(
        `/receipt.order/insert_batch_registration/${id}`,
        formData,
      )
    },
    delete: (id: number) => {
      return this.api.post('/dataset/receipt.order.import.sequence/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  StoreCashierBatch = {
    upload: async (file: any, sequenceId: number) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `storecashier/insert_batch_registration/${sequenceId}`,
        formData,
      )
    },
  }

  DisplayPattern = {
    get: () => {
      return this.api.get('/product.display.pattern', {
        isShowNotificationError: false,
      })
    },
  }

  OrderDisplayPattern = {
    post: (data: any) => {
      return this.api.post(`/dataset/order.display_pattern/create`, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post(`/dataset/order.display_pattern/write`, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  ProductionControl = {
    ImportSequence: this._generateCrud('production.control.import.sequence'),
    upload: async (name, file, id) => {
      const formData = new FormData()
      formData.append(name, file)

      const response = await this.api.post(
        `/dataset/production.control/${id}/upload_files`,
        formData,
      )

      return response
    },
    write: async (id: number | string, data: any) => {
      const response = await this.api.post(
        '/dataset/production.control/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )

      return response
    },
    unlink: (ids: number[]) => {
      return this.api.post('/dataset/production.control/unlink', {
        params: {
          args: [ids],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    partialDelivery: (ids: number[]) => {
      return this.api.post('/dataset/production.control/partial_delivery', {
        params: {
          args: [ids],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    checkConditionForState: (ids: number[][]) => {
      return this.api.post(
        '/dataset/production.control/check_condition_for_state',
        {
          params: {
            args: [...ids],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    createArrivals: (
      priorityId: number,
      ids: number[],
      context?: Record<string, string>,
    ) => {
      return this.api.post('/dataset/production.control/create_arrivals', {
        params: {
          args: [ids, priorityId],
          kwargs: {
            context: {
              ...context,
            },
          },
        },
      })
    },
    createPurchase: (ids: number[], purchase_date: string) => {
      return this.api.post('/dataset/production.control/create_purchase', {
        params: {
          args: [ids, purchase_date],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    readProductionControl: (
      args: SearchParams & { display_option: 'sku-unit' | 'product-unit' },
    ) => {
      return this.api
        .post('/dataset/production.control/read_production_control', {
          params: {
            args: [args],
            kwargs: {},
          },
        })
        .then((res) => res.data)
    },
    exportTag: ({
      display_option,
      ids,
      fileType = 'excel',
      encoding,
    }: {
      display_option?: string
      ids: number[]
      encoding?: string
      fileType?: 'excel' | 'csv'
    }) => {
      return this.api.post(
        fileType === 'csv'
          ? API_ROUTE.PRODUCTION_CONTROL.TAG_EXPORT_CSV
          : API_ROUTE.PRODUCTION_CONTROL.TAG_EXPORT_EXCEL,
        {
          display_option,
          ids,
          encoding,
          lang: this.currentLanguage,
        },
        {
          responseType: 'blob',
        },
      )
    },
    LogHistory: {
      search: this._generateCrud('production.control.log').search,
    },
  }

  ReplenishmentInstruction = {
    create: (data: IReplenishmentPost) => {
      return this.api.post(
        '/dataset/store.product.replenishment.instruction.setting/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  ReplenishmentPlan = {
    create: (data: IReplenishmentPlanPost) => {
      return this.api.post('/dataset/store.replenishment.setting/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update: (id: number, data: IReplenishmentPlanPost) => {
      return this.api.post('/dataset/store.replenishment.setting/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  ReplenishmentInstructionPlan = {
    create: (data: IReplenishmentInstructionPlan) => {
      return this.api.post('/dataset/store.replenishment.instruction/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    onChange: (data) => {
      return this.api.post(
        '/dataset/store.replenishment.instruction/onchange',
        {
          params: {
            args: [
              [],
              data,
              '',
              {
                product_id: '1',
                store_id: '1',
                method: '',
                pattern: '',
                min_value: '',
                max_value: '',
                inventory_qty: '',
                sale_qty: '',
                period_sale_qty: '',
                replenish_plan_qty: '',
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    toShipping: (ids: number[]) => {
      return this.api.post(
        '/dataset/store.replenishment.instruction/to_shipping',
        {
          params: {
            args: [ids],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  ProductReplenishmentSetting = {
    create: (data: ISafetyStockPost[]) => {
      return this.api.post('/replenishment/setting/product/write', {
        params: {
          records: data,

          kwargs: {},
        },
      })
    },
  }

  StoreReplenishmentRequest = {
    create: (data: IReplenishmentRequest) => {
      return this.api.post('/dataset/store.replenishment.request/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
  }

  FitMaster = {
    search: this._generateCrud('fit.master').search,
    create: (data: any) => {
      return this.api.post('/dataset/fit.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/fit.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/fit.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  AdjustmentTypeMaster = {
    ...this._generateCrud('account.adjustment'),
    setAsDefault: (id: number) => {
      return this.api.post(
        '/dataset/account.adjustment/set_default_adjustment_receivable',
        {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
  }

  TaxMaster = {
    ...this._generateCrud('account.tax'),
    create: (data: any) => {
      return this.api.post('/dataset/account.tax/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post('dataset/account.tax/write/', {
        params: {
          args: [id, data],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (ids: number, _lang?: string) => {
      return this.api.post('/dataset/account.tax/unlink', {
        params: {
          args: [ids],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }
  TaxMasterSymbol = {
    ...this._generateCrud('account.tax.symbol'),
  }

  EmployeeLimit = {
    ...this._generateCrud('employee.transaction.limit'),
    create: (data: any) => {
      return this.api.post('/dataset/employee.transaction.limit/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    update: (id: number, data: any) => {
      return this.api.post('dataset/employee.transaction.limit/write/', {
        params: {
          args: [id, data],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (ids: number, _lang?: string) => {
      return this.api.post('/dataset/employee.transaction.limit/unlink', {
        params: {
          args: [ids],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }

  CategoryMaster = {
    search: this._generateCrud('category.master').search,
    create: (data: any) => {
      return this.api.post('/dataset/category.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/category.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/category.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  FrontFitMaster = {
    search: this._generateCrud('front.fit.master').search,
    create: (data: any) => {
      return this.api.post('/dataset/front.fit.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/front.fit.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/front.fit.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  CollarMaster = {
    ...this._generateCrud('collar.master'),
    create: (data: any) => {
      return this.api.post('/dataset/collar.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/collar.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/collar.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  LengthMaster = {
    search: (params?: SearchParams) => {
      return this._search('/dataset/length.master/search_read/', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/length.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/length.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/length.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  SleeveMaster = {
    search: this._generateCrud('sleeve.master').search,
    create: (data: any) => {
      return this.api.post('/dataset/sleeve.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/sleeve.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/sleeve.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  GenderMaster = {
    ...this._generateCrud('gender.master'),
    search: (params?: SearchParams) => {
      return this._search('/dataset/gender.master/search_read/', params)
    },
    create: (data: any) => {
      return this.api.post('/dataset/gender.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/gender.master/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/gender.master/unlink', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  SeasonMaster = {
    ...this._generateCrud('calendar.season'),
    create: (data: any) => {
      return this.api.post('/dataset/calendar.season/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/calendar.season/write', {
        params: {
          args: data,
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (data: any) => {
      return this.api.post('/dataset/calendar.season/unlink', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }

  SeasonNameMaster = {
    ...this._generateCrud('calendar.season.label'),
    create: (data: any) => {
      return this.api.post('/dataset/calendar.season.label/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('dataset/calendar.season.label/write', {
        params: {
          args: data,
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (data: any) => {
      return this.api.post('/dataset/calendar.season.label/unlink', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }

  ShipmentInstruction = {
    create: (data: IShipmentPost) => {
      return this.api.post('/dataset/stock.picking/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/stock.picking/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    getPickingType: () => {
      return this.api.post('/dataset/stock.move/get_shipping_picking_type', {
        params: {
          args: [],

          kwargs: {},
        },
      })
    },
  }

  ShipmentInstructionForEC = {
    create: (data: IShipmentPost) => {
      return this.api.post('/dataset/stock.move/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/stock.move/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    getPickingType: () => {
      return this.api.post('/dataset/stock.move/get_shipping_picking_type', {
        params: {
          args: [],

          kwargs: {},
        },
      })
    },
  }

  ShipmentConfirmationProcess = {
    create: (data: IShipmentConfirmationPost) => {
      return this.api.post('/dataset/stock.move/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/stock.move/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    getPickingType: () => {
      return this.api.post('/dataset/stock.move/get_shipping_picking_type', {
        params: {
          args: [],

          kwargs: {},
        },
      })
    },
  }

  UserMaster = {
    ...this._generateCrud('res.users'),
    create: (data: any) => {
      return this.api.post('/dataset/res.users/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              no_reset_password: true,
            },
          },
        },
      })
    },
    update: (id, data: any) => {
      return this.api.post('dataset/res.users/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (data: any, _lang?: string) => {
      return this.api.post('/dataset/res.users/unlink', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    setPassword: (id: number, newPassword: string) => {
      return this.api.post('/dataset/res.users/write', {
        params: {
          args: [id],
          kwargs: {
            new_password: newPassword,
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/res.users/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    custom_write: (params: SearchParams, data: any) => {
      return this.api.post('/dataset/res.users/custom_write', {
        params: {
          args: [params, data],
          kwargs: {},
        },
      })
    },
    upload: (file: RcFile) => {
      const formData = new FormData()
      formData.append('file', file)

      return this.api.post('/users/upload', formData)
    },
    send_email: (data: any) => {
      return this.api.post('/dataset/res.users/send_email', {
        params: {
          args: [data],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    takeover_admin: (data: any) => {
      return this.api.post('/dataset/res.users/takeover_admin', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
  }

  OrderImportSequence = {
    create: (data) => {
      return this.api.post('/dataset/order.import.sequence/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/order.import.sequence/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    delete: (id: number) => {
      return this.api.post('/dataset/order.import.sequence/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  QuotationImportSequence = {
    create: (data) => {
      return this.api.post('/dataset/quotation.import.sequence/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/quotation.import.sequence/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/quotation.import.sequence/unlink', {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },
  }

  StoreReplenishmentPlan = {
    regist: (ids: number[]) => {
      return this.api.post('/dataset/store.replenishment.plan/register', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/store.replenishment.plan/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
  }

  PurchaseSlipList = {
    ...this._generateCrud('account.move'),
    Line: this._generateCrud('account.move.line'),
    cancelPurchaseSlip: (ids: number[]) => {
      return this.api.post('/dataset/account.move/button_cancel', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    search_read: (params?: SearchRead) =>
      this._search('/dataset/order.payment/search_read/', params),
    read: async (
      props: SearchRead = {
        domain: [],
        limit: 10,
        offset: 0,
      },
    ): Promise<ReactQueryResponse<OrderPayment>> => {
      const { domain, limit, offset } = props
      const res = (await this.api
        .post('/dataset/order.payment/search_read/', {
          params: {
            domain,

            kwargs: {
              context: { lang: this.currentLanguage },
            },
            limit,
            offset,
            sort: 'write_date desc',
          },
        })
        .then((res) => res.data)) as any

      if (has(res, 'error')) {
        throw new Error(res.error.message)
      }
      return res
    },

    createPayment: (data: OrderPaymentCreate) => {
      return this.api.post('/dataset/order.payment/create/', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    softDelete: (id: number[]) => {
      return this.api.post('/dataset/order.payment/soft_unlink/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    delete: (id: number[]) => {
      return this.api.post('/dataset/order.payment/unlink/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    updatePayment: (id: number, data: Partial<OrderPaymentCreate>) => {
      return this.api.post('/dataset/order.payment/write/', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    createPaymentLine: (data: OrderPaymentLineCreate) => {
      return this.api.post('/dataset/order.payment.line/create/', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    updatePaymentLine: (
      id: number,
      data: Omit<OrderPaymentLineCreate, 'order_payment_id'>,
    ) => {
      return this.api.post('/dataset/order.payment.line/write/', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    deletePaymentLine: (id: number) => {
      return this.api.post('/dataset/order.payment.line/unlink/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    getPaymentLine: (
      orderPaymentId: string | number,
    ): Promise<PaymentLine[]> => {
      return this.api
        .post('/dataset/order.payment.line/search_read/', {
          params: {
            domain: [['order_payment_id', '=', Number(orderPaymentId)]],
            sort: 'create_date asc',
          },
        })
        .then(transformResponse)
    },
    handlePayment: (id: number) => {
      return this.api.post('/dataset/order.payment/handling_payment/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  ArrivalSlip = {
    search: async (params) => {
      return this._search<ArrivalSlipData>(
        '/dataset/stock.picking/search_read/',
        params,
      )
    },
    create: (data: CreateArrivalSlip) => {
      return this.api.post('/dataset/stock.picking/create/', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              skip_auto_recepient_fill: true,
            },
          },
        },
      })
    },
    batchCreate: (data) => {
      return this._batchCreate('stock.picking', data)
    },
    update: async (id: number, data: CreateArrivalSlip) => {
      return this.api.post('/dataset/stock.picking/write/', {
        params: {
          args: [id, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    softDelete: (id: number[]) => {
      return this.api.post('/dataset/stock.picking/delete_picking/', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
    delete: async (id: number) => {
      return this.api.post('/dataset/arrival.slip.import.sequence/unlink/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    get_import_pattern: async (
      id = '',
    ): Promise<{
      result: { id: number; name: string }[]
    }> => {
      return this.api
        .post(`/dataset/arrival.slip.import.sequence/read/`, {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((r) => r.data)
    },
    post_import_pattern: (data: any) => {
      return this.api.post(`/dataset/arrival.slip.import.sequence/create/`, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update_import_pattern_name: (id: number | string, data: any) => {
      return this.api.post(`/dataset/arrival.slip.import.sequence/write/`, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    File: this._generateCrud('picking.document'),
  }

  ArrivalSlipLine = {
    search: async (params?: SearchRead) => {
      return this._search<ArrivalSlipLine>(
        '/dataset/stock.move/search_read/',
        params,
      )
    },
    create: async (data: Partial<ArrivalSlipLine>) => {
      return this._create('/dataset/stock.move/create/', data)
    },
    confirm: async (id: number) => {
      return this.api.post('/dataset/stock.move/confirm/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    update: async (id: number, data: Partial<ArrivalSlipLine>) => {
      return this._update<Partial<ArrivalSlipLine>>(
        '/dataset/stock.move/write/',
        {
          id,
          data,
        },
      )
    },
    delete: async (id: number) => {
      return this.api.post('/dataset/stock.move/unlink/', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    Line: this._generateCrud('stock.move.line'),
    PastLine: this._generateCrud('past.stock.move.line'),
  }

  Inventory = {
    search: async (params?: SearchRead) => {
      return this._search<Inventory>(
        '/dataset/stock.picking/search_read/',
        params,
      )
    },
  }

  InventoryEvaluation = {
    ...this._generateCrud('inventory.evaluation.change'),
    search: async (params?: SearchRead) => {
      return this._search<InventoryEvaluation>(
        '/dataset/inventory.evaluation.change/search_read/',
        params,
      )
    },
    actionConfirm: (ids: number | number[]) => {
      return this.api.post(
        '/dataset/inventory.evaluation.change/action_confirm',
        {
          params: {
            args: [ids],
            kwargs: {},
          },
        },
      )
    },
    actionCancel: (ids: number | number[]) => {
      return this.api.post(
        '/dataset/inventory.evaluation.change/action_cancel',
        {
          params: {
            args: [ids],
            kwargs: {},
          },
        },
      )
    },
  }

  ProductTemplate = {
    ...this._generateCrud('product.template'),
    get_messages: async (id: number) => {
      return this.api.post('/dataset/product.template/get_messages', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
  }

  ProductSewing = this._generateCrud('product.specification.sewing')

  ProductFabric = this._generateCrud('product.specification.fabric')

  ProductPacking = this._generateCrud('product.specification.packing')

  ProductInspection = this._generateCrud('product.specification.inspection')

  ProductPattern = this._generateCrud('product.specification.pattern.store')

  ProductSecondary = this._generateCrud(
    'product.specification.secondary.process',
  )

  Budget = {
    get: () => this.api.get('/accounts/budgets'),

    create: (data: BudgetItem) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/budgets', formData)
    },

    update: (id: string | number, data: BudgetItem) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.patch(`/accounts/budgets/${id}`, formData)
    },

    delete: (id: string | number) => this.api.delete(`/accounts/budgets/${id}`),

    getBudgetAndOrganizationsAndVersion: (
      forecasting_organization_types?: string,
    ) =>
      this.api.get(
        `/accounts/budgets_versions_organizations${
          forecasting_organization_types
            ? `?forecasting_organization_types=${forecasting_organization_types}`
            : ''
        }`,
      ),
  }

  ResourcePlan = {
    get: (id?: any) => {
      if (id) {
        return this.api.get(`/accounts/hrplan_masters?budget_id=${id}`)
      }
      return this.api.get('/accounts/hrplan_masters')
    },

    import: (data: { budget_id: any; file: any }) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/hrplan_masters/import', formData)
    },
  }

  MasterCost = {
    getListMaster: () => this.api.get('/accounts/accounttitle_masters'),

    create: (data: DataType) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/accounttitle_masters', formData)
    },

    update: (id: number | string, data: DataType) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.patch(`/accounts/accounttitle_masters/${id}`, formData)
    },

    import: (data: { file: any }) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/accounttitle_masters/import', formData)
    },
  }

  MasterAdditionalCost = {
    get: () => this.api.get('/accounts/accounttitle_submasters'),

    getListPaymentterms: () =>
      this.api.get('/accounts/accounttitle_submasters/paymentterms'),

    create: (data: DataTypeMasterAdditionalCost) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/accounttitle_submasters', formData)
    },

    update: (id: number, data: DataTypeMasterAdditionalCost) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.patch(`accounts/accounttitle_submasters/${id}`, formData)
    },

    import: (data: { file: any }) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/accounttitle_submasters/import', formData)
    },
  }

  ActionElement = {
    get: () => this.api.get('/accounts/actionelement_masters'),
  }

  WholesalePipelineWeight = {
    ...this._generateCrud('wholesale.pipline.weight'),
    create: async <Data = any, Response = ResponseCreate | ResponseError>(
      data: Data,
      kwargs: Record<string, any> = {},
    ) => {
      const { data: resData } = await this.api.post<
        any,
        AxiosResponse<Response>
      >(`/dataset/wholesale.pipline.weight/create/`, {
        params: {
          args: [0, data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
            ...kwargs,
          },
        },
      })

      if (isError(resData)) {
        throw new Error(resData.error.data.message)
      }

      return (resData as unknown as ResponseCreate).result
    },
  }

  ShipmentSlip = {
    ...this._generateCrud('stock.picking'),
    create: (data) => {
      return this.api.post('/dataset/stock.picking/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
              skip_auto_recepient_fill: true,
            },
          },
        },
      })
    },
    batchCreate: (data) => {
      return this._batchCreate('stock.picking', data)
    },

    confirm: (id) => {
      return this.api.post('/dataset/stock.picking/action_confirm_picking', {
        params: {
          args: [id],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    recalculateStock: (id) => {
      return this.api.post(
        '/dataset/stock.picking/action_recalculate_available_quantity',
        {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },

    update: (id, data) => {
      return this.api.post('/dataset/stock.picking/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    createSales: (id: number) => {
      return this.api.post('/dataset/stock.picking/action_create_sales', {
        params: {
          args: [id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    File: this._generateCrud('picking.document'),
  }

  ShipmentSlipList = {
    delete: (id: number[] | string[]) => {
      return this.api.post(`/dataset/stock.picking/delete_picking`, {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/stock.picking/unarchives/', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
  }

  ShipmentSlipLine = {
    ...this._generateCrud('stock.move'),
    create: (data) => {
      return this.api.post('/dataset/stock.move/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    confirm: (id) => {
      return this.api.post('/dataset/stock.move/confirm', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/stock.move/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    delete: (id: number | number[]) => {
      return this.api.post('/dataset/stock.move/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  SaleOrder = {
    ...this._generateCrud('sale.order'),
    batchCreate: (data) => {
      return this._batchCreate('sale.order', data)
    },
    checkForBundleDiscount: ({
      warehouse_id,
      product_data,
    }: {
      warehouse_id: number
      product_data: any[]
    }) => {
      return this.api.post('/dataset/sale.order/check_for_bundle_discount', {
        params: {
          args: [],
          kwargs: {
            warehouse_id,
            product_data,
          },
        },
      })
    },
    confirmSale: ({ saleOrderId }: { saleOrderId: number }) => {
      return this.api.post('/dataset/sale.order/action_confirm_sale', {
        params: {
          args: [saleOrderId],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    previewSaleSlip: ({
      id,
      fields,
      group,
    }: {
      id: number
      fields?: any
      group: string
    }) => {
      return this.api.post('/dataset/sale.order/get_preview_slip_data', {
        params: {
          args: [
            {
              id,
              fields,
              group,
            },
          ],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  SaleOrderLine = {
    ...this._generateCrud('sale.order.line'),
  }

  Sales = {
    create: (data, kwargs?: { context: Record<string, string> }) => {
      return this.api.post('/dataset/sales.slip/create', {
        params: {
          args: [data],
          kwargs,
        },
      })
    },

    massBilling: (
      salesId: number | number[],
      { context }: { context?: Record<string, string> } = {},
    ) => {
      return this.api.post('/dataset/sale.order/mass_create_billing', {
        params: {
          args: [salesId],
          kwargs: {
            context: { ...context },
          },
        },
      })
    },

    batchCreateBilling: (
      ids: number[],
      { context }: { context?: Record<string, string> } = {},
    ) => {
      return this.api.post('/dataset/sale.order/batch_create_billing', {
        params: {
          args: [ids],
          kwargs: {
            context: { ...context },
          },
        },
      })
    },

    update: (id, data, kwargs?: { context: Record<string, string> }) => {
      return this.api.post('/dataset/sales.slip/write', {
        params: {
          args: [id, data],
          kwargs,
        },
      })
    },

    delete: (
      id: number | number[],
      { context }: { context?: Record<string, string> } = {},
    ) => {
      return this.api.post('/dataset/sale.order/unlink', {
        params: {
          args: [id],
          kwargs: {
            context: { ...context },
          },
        },
      })
    },

    checkProperForProduct: async (
      product_id: number,
      final_product_price: number,
      shipment_date: string,
    ) => {
      const { data } = await this.api.post(
        '/dataset/sale.order/check_proper_for_product',
        {
          params: {
            args: [],
            kwargs: {
              product_id,
              final_product_price,
              shipment_date,
            },
          },
        },
      )

      if (isError(data)) {
        throw new Error(data.error.message)
      }

      return data.result.proper_status as boolean
    },
  }

  SalesLine = {
    create: (data) => {
      return this.api.post('/dataset/sales.slip.line/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    confirm: (id) => {
      return this.api.post('/dataset/sales.slip.line/confirm', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/sales.slip.line/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    delete: (id: number | number[]) => {
      return this.api.post('/dataset/sales.slip.line/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  Billing = {
    create: (data) => {
      return this.api.post('/dataset/sales.billing/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    confirm: (
      billingId: number[],
      kwargs: { [key: string]: Record<string, string> } = {},
    ) => {
      return this.api.post('/dataset/sales.billing/confirm_billing/', {
        params: {
          args: [billingId],
          kwargs,
        },
      })
    },

    paymentConfirm: (
      billingId: number,
      kwargs: { [key: string]: Record<string, string> } = {},
    ) => {
      return this.api.post('/dataset/sales.billing/confirm_payment/', {
        params: {
          args: [billingId],
          kwargs,
        },
      })
    },

    delete: (id: number[] | string[], options?: { force?: boolean }) => {
      const args = [id] as any[]
      if (options?.force) {
        args.push(true)
      }
      return this.api.post(`/dataset/sales.billing/unlink`, {
        params: {
          args,

          kwargs: {},
        },
      })
    },

    generate: (data) => {
      return this.api.post('/dataset/sales.billing/generate', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    handlingPayment: (id) => {
      return this.api.post('/dataset/sales.billing/handling_payment', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    getGroupedTax: (id: number) => {
      return this.api.post('/dataset/sales.billing/get_grouped_tax', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/sales.billing/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    updatePaymentDueDate: (id, data) => {
      return this.api.post('/dataset/sales.billing/update_payment_due_date', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    getBillingLinesInvoiceByVersionGroup: (id: number) => {
      return this.api.post(
        '/dataset/sales.billing/get_sales_billing_line_ids',
        {
          params: {
            args: [id],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    BatchSession: {
      ...this._generateCrud('sales.billing.batch.session'),
      downloadPDF: ({ id, filename }: { id: number; filename?: string }) => {
        return this.api
          .post(`/dataset/sales.billing.batch.session/action_download_pdf`, {
            responseType: 'blob',
            params: {
              args: [
                id, // sales.billing.batch.session ID
              ],
              kwargs: {},
            },
          })
          .then((resp) => {
            const url = window.URL.createObjectURL(
              new Blob([resp.data], { type: 'application/pdf;charset=utf-8' }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `${filename ?? `download_${Date.now()}`}.pdf`,
            )
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
          })
      },
      retryJob: (id: number | number[]) => {
        return this.api.post(
          '/dataset/sales.billing.batch.session/action_retry_job',
          {
            params: {
              args: [id],
              kwargs: {},
            },
          },
        )
      },
      requestDownload: ({
        id,
        direct_download,
      }: {
        id: number | number[]
        direct_download?: boolean
      }) => {
        return this.api.post(
          '/dataset/sales.billing.batch.session/download_pdf',
          {
            params: {
              args: [
                id,
                {
                  direct_download,
                },
              ],
              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )
      },
      requestDownloadByInvoiceIds: ({
        invoice_ids,
      }: {
        invoice_ids: number[]
      }) => {
        return this.api.post(
          '/dataset/sales.billing.batch.session/download_pdf',
          {
            params: {
              args: [
                0,
                {
                  invoice_ids,
                },
              ],
              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )
      },
      getAllInvoiceIds: (id: number | number[]) => {
        return this.api.post(
          '/dataset/sales.billing.batch.session/get_all_invoice_ids',
          {
            params: {
              args: [id],
              kwargs: {},
            },
          },
        )
      },
    },
  }

  BillingLine = {
    create: (data) => {
      return this.api.post('/dataset/sales.billing.lines/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },

    update: (id, data) => {
      return this.api.post('/dataset/sales.billing.lines/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    getProductRelatedSales: (sales_no) => {
      return this.api.post(
        '/dataset/sales.billing.lines/get_sales_no_related_product_lists',
        {
          params: {
            args: [0],
            kwargs: { sales_no },
          },
        },
      )
    },
    delete: (id: number | number[]) => {
      return this.api.post('/dataset/sales.billing.lines/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }
  BillingInvoice = {
    ...this._generateCrud('sales.billing.invoice'),
    requestDownload: (id: number | number[]) => {
      return this.api.post('/dataset/sales.billing.invoice/download_pdf', {
        params: {
          args: [id],
          kwargs: {},
        },
      })
    },
  }
  CommentResult = {
    delete: (id: number) => {
      return this.api.post('/dataset/mail.message/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  WebsiteSetting = {
    get: (id?) => {
      if (id) {
        return this.api.get(
          `/accounts/paymentterms_setting?hrplan_budget_id=${id}`,
        )
      }
      return this.api.get('/accounts/paymentterms_setting')
    },

    createAndUpdate: (data: {
      hrplan_budget_id: number
      data: DatatypeWebsiteSetting[]
    }) => this.api.post('/accounts/paymentterms_setting/batch_create', data),
  }

  VirtualWarehouse = {
    ...this._generateCrud('stock.location'),
    create: (data) => {
      return this.api.post('/dataset/stock.location/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update: (id, data) => {
      return this.api.post('/dataset/stock.location/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number[]) => {
      return this.api.post('/dataset/stock.location/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  SalesAnalysisForAllocation = {
    update: (id, data) => {
      return this.api.post(
        '/dataset/inventory.allocation.priority_setting/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    delete: (ids: any) => {
      return this.api.post(
        '/dataset/inventory.allocation.priority_setting/unlink',
        {
          params: {
            args: [ids],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  StoreMaster = {
    ...this._generateCrud('res.partner'),
    create: (data: any) => {
      return this.api.post('/dataset/res.partner/create', {
        params: {
          args: [data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    write: (id: any, data: any) => {
      return this.api.post('/dataset/res.partner/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (ids: any) => {
      return this.api.post('/dataset/res.partner/unlink', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    softDelete: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/soft_unlink', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/unarchives/', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
    nameSearch: (name: string, limit: number) => {
      return this.api.post('/dataset/res.partner/name_search', {
        params: {
          args: [],
          kwargs: {
            name,
            args: [],
            operator: 'ilike',
            limit,
          },
        },
      })
    },
  }

  ProductMaximumSetting = {
    update: (data) => {
      return this.api.patch('/store_allocation/product', {
        params: {
          records: data,

          kwargs: {},
        },
      })
    },
  }

  SalesChannelMaster = {
    ...this._generateCrud('sales.channel.master'),
    create: (data: { name: string }) => {
      return this.api.post('/dataset/sales.channel.master/create', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: { name: string }) => {
      return this.api.post('/dataset/sales.channel.master/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/sales.channel.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  CreateTemplateForAllocation = {
    update: (data) => {
      return this.api.patch('/store_allocation/product/store', {
        params: {
          records: data,

          kwargs: {},
        },
      })
    },
  }

  SalesAnalysisForAllocationProcess = {
    updatePriority: (data) => {
      return this.api.put('/store_allocation/priority/write', {
        params: {
          priority_type: data,

          kwargs: {},
        },
      })
    },
    updatePriorityStore: (data) => {
      return this.api.post('/store_allocation/priority_store/write', {
        params: {
          records: data,

          kwargs: {},
        },
      })
    },
  }

  FirstStoreGroupMaster = {
    ...this._generateCrud('first.store.group.master'),
    create: (data: { name: string }) => {
      return this.api.post('/dataset/first.store.group.master/create', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: { name: string }) => {
      return this.api.post('/dataset/first.store.group.master/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/first.store.group.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  SecondStoreGroupMaster = {
    ...this._generateCrud('second.store.group.master'),
    create: (data: { name: string }) => {
      return this.api.post('/dataset/second.store.group.master/create', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: { name: string }) => {
      return this.api.post('/dataset/second.store.group.master/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/second.store.group.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  ReplenishmentSettingStore = {
    search: (storeIds) => {
      return this.api.post('/replenishment/setting/store/search_read', {
        params: {
          store_id: storeIds,
        },
      })
    },
    write: (store_id, records) => {
      return this.api.post('/replenishment/setting/store/write', {
        params: {
          store_id,
          records,
        },
      })
    },
  }

  ThirdStoreGroupMaster = {
    ...this._generateCrud('third.store.group.master'),
    create: (data: { name: string }) => {
      return this.api.post('/dataset/third.store.group.master/create', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: { name: string }) => {
      return this.api.post('/dataset/third.store.group.master/write', {
        params: {
          args: [id, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    unlink: (id: number, _lang?: string) => {
      return this.api.post('/dataset/third.store.group.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  CreateReplenishmentPlan = {
    write: (date, records) => {
      return this.api.post('/replenishment/plan/store/write', {
        params: {
          replenishment_date: date,
          records,
        },
      })
    },
    generate: (args?: any[], kwargs?: Record<string, any>) => {
      return this.api.post(
        '/dataset/store.product.replenishment.instruction/generate_all_replenishment_plans',
        {
          params: {
            args: args ?? [],
            kwargs: {
              ...(kwargs || {}),
            },
          },
        },
      )
    },
  }

  CreateReplenishmentInstruction = {
    create: (ids) => {
      return this.api.post('/replenishment/product/instruction/create', {
        params: {
          id: ids,
        },
      })
    },
  }

  CreateReplenishmentInstructionList = {
    createShipping: (ids) => {
      return this.api.post(
        '/dataset/store.product.replenishment.instruction/create_shipping_instruction',
        {
          params: {
            args: [ids],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    createAllShipping: () => {
      return this.api.post(
        '/dataset/store.product.replenishment.instruction/create_all_shipping_instructions',
        {
          params: {
            args: [],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    cancelShipping: (ids) => {
      return this.api.post(
        '/dataset/store.product.replenishment.instruction/cancel_replenishments',
        {
          params: {
            args: [ids],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    update: async (datas) => {
      const { id, ...data } = datas
      return this.api.post(
        '/dataset/store.product.replenishment.instruction/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  SaleSettingDetail = {
    create: (data) => {
      return this.api.post('/sale_promotion/create', {
        params: data,
      })
    },
    write: (data) => {
      return this.api.post('/sale_promotion/write', {
        params: data,
      })
    },
  }

  SetSalesDetail = {
    create: (data) => {
      return this.api.post('/set_product/create', {
        params: data,
      })
    },
    write: (data) => {
      return this.api.post('/set_product/write', {
        params: data,
      })
    },
    delete: (id) => {
      return this.api.post('/set_product/delete', {
        params: id,
      })
    },
  }

  CustomerMasterImportSequences = this._generateCrud(
    'customer.master.import.sequence',
  )

  StoreMasterImportSequences = this._generateCrud(
    'store.master.import.sequence',
  )

  ShipmentImportSequences = {
    ...this._generateCrud('shipment.slip.import.sequence'),
  }

  CustomerMasterBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/customer_master/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  FixedAssetsAndDepreciationSetting = {
    get: (id?) => {
      if (id) {
        return this.api.get(
          `/accounts/depreciation_transactions?depreciation_budget_id=${id}`,
        )
      }
      return this.api.get('/accounts/depreciation_transactions')
    },

    getOrganization: () =>
      this.api.get('/accounts/forecasting_organization_masters/options'),

    create: (datas: {
      depreciation_budget_id: any
      data: ListResourcePlan[]
    }) => {
      return this.api.post(
        '/accounts/depreciation_transactions/batch_create',
        datas,
      )
    },

    import: (datas: { depreciation_budget_id: any; file: any }) => {
      const formData = new FormData()
      Object.entries(datas).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post(
        '/accounts/depreciation_transactions/import',
        formData,
      )
    },
  }

  StoreMasterBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/store_master/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  ProductFirstClassification = {
    search: (params?: SearchParams) => {
      return this._search(
        '/dataset/first.classification.master/search_read/',
        params,
      )
    },
    create: (data: UseProductData) => {
      return this.api.post('/dataset/first.classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (id: number, _lang?: string) => {
      return this.api.post('/dataset/first.classification.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/first.classification.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductSecondClassification = {
    search: (params?: SearchParams) => {
      return this._search(
        '/dataset/second.classification.master/search_read/',
        params,
      )
    },
    create: (data: UseProductData) => {
      return this.api.post('/dataset/second.classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (id: number, _lang?: string) => {
      return this.api.post('/dataset/second.classification.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/second.classification.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductThirdClassification = {
    search: (params?: SearchParams) => {
      return this._search(
        '/dataset/third.classification.master/search_read/',
        params,
      )
    },
    create: (data: UseProductData) => {
      return this.api.post('/dataset/third.classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (id: number, _lang?: string) => {
      return this.api.post('/dataset/third.classification.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/third.classification.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductFourthClassification = {
    search: (params?: SearchParams) => {
      return this._search(
        '/dataset/fourth.classification.master/search_read/',
        params,
      )
    },
    create: (data: UseProductData) => {
      return this.api.post('/dataset/fourth.classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (id: number, _lang?: string) => {
      return this.api.post('/dataset/fourth.classification.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/fourth.classification.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  ProductFifthClassification = {
    search: (params?: SearchParams) => {
      return this._search(
        '/dataset/fifth.classification.master/search_read/',
        params,
      )
    },
    create: (data: UseProductData) => {
      return this.api.post('/dataset/fifth.classification.master/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (id: number, _lang?: string) => {
      return this.api.post('/dataset/fifth.classification.master/unlink', {
        params: {
          args: [[id]],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/fifth.classification.master/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  UOMMaster = {
    ...this._generateCrud('uom.master'),
    SubUom: this._generateCrud('sub.uom.master'),
  }

  TagPrice = {
    ...this._generateCrud('tag.price'),
    Fields: this._generateCrud('tag.price.fields'),
    GetTags: (id?: number | number[], params?: SearchParams) => {
      return this.api.post('/dataset/tag.price/get_tags', {
        params: {
          args: [id, params],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    create_tag: (data: { name: string; reference_model: string }) => {
      return this.api.post('/dataset/tag.price/create_tag', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    get_model_fields: (reference_model: string) => {
      return this.api.post('/dataset/tag.price/get_model_fields', {
        params: {
          args: [{ reference_model }],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write_tag_fields_byTagId: (tagId: number, data: any) => {
      return this.api.post('/dataset/tag.price/write_tag', {
        params: {
          args: [tagId, data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    exportTag: ({
      display_option,
      fileType = 'excel',
      encoding,
      tag_id,
      domain_field_id,
      domain_model,
      domain,
      headers,
    }: {
      tag_id: number
      display_option?: string
      encoding?: string
      fileType?: 'excel' | 'csv'
      domain_model?: string
      domain_field_id?: string
      domain?: any[]
      headers?: Record<string, any>
    }) => {
      return this.api.post(
        fileType === 'csv'
          ? API_ROUTE.TAX_EXPORT_SETTING.DOWNLOAD_TAG_CSV
          : API_ROUTE.TAX_EXPORT_SETTING.DOWNLOAD_TAG_XLS,
        {
          params: {
            args: [
              {
                tag_id,
                domain,
                domain_model,
                domain_field_id,
                display_option,
                encoding,
                headers,
              },
            ],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
  }

  ShipmentBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/shipment_slip/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  ArrivalBatchRegistration = {
    upload: (id, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.api.post(
        `/arrival_slip/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  Disposal = {
    fetch_product: async ({
      locationId,
      code,
      name,
      fields = [],
      limit,
      offset,
    }) => {
      return this._search('/dataset/stock.quant/search_read/', {
        domain: [
          ['location_id', '=', locationId],
          ['product_id.name', 'ilike', name],
          ['product_id.product_code', 'ilike', code],
        ],
        fields: fields ?? [],
        limit,
        offset,
        sort: 'id desc',
      })
    },
    create: async (data: CreateDisposal) => {
      return this._create<CreateDisposal>(
        '/dataset/stock.scrap.slip/create/',
        data,
      )
    },
    write: (id: number, data: Partial<CreateDisposal>) => {
      return this.api.post('/dataset/stock.scrap.slip/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    read: async (
      disposalId: number,
      fields: string[] = [],
      lineFields: string[] = [],
    ) => {
      return this.api
        .post('/dataset/stock.scrap.slip/read/', {
          params: {
            args: [disposalId, fields],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
        .then((res) => res.result[0])
        .then(async (res) => {
          if (res.line_ids.length > 0) {
            const products = await this._search(
              '/dataset/stock.scrap/search_read/',
              {
                domain: [['id', 'in', res.line_ids]],
                fields: lineFields,
                sort: 'product_code asc, color_sequence asc, color_code asc, size_sequence asc, size_code asc',
                context: {
                  use_ldx_custom_join: true,
                },
              },
            ).then((res) => res.result)

            return {
              ...res,
              line_ids: products?.records || [],
            }
          }
          return res
        })
    },
    confirm: (disposalInstructionId: number) => {
      return this.api.post('/dataset/stock.scrap.slip/action_confirm_scrap/', {
        params: {
          args: [disposalInstructionId],

          kwargs: {},
        },
      })
    },
    unlink_product_line: (productId: number | number[]) => {
      return this.api.post('/dataset/stock.scrap/unlink/', {
        params: {
          args: [productId],

          kwargs: {},
        },
      })
    },
    itemClassification: {
      ...this._generateCrud('stock.scrap.classification'),
    },
  }

  Return = {
    search_read: (params?: SearchRead) => {
      return this._search<ReturnSearchRead>(
        API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.SEARCH_READ,
        {
          fields: [
            'display_name',
            'id',
            'product_line_ids',
            'return_from',
            'return_from_classification',
            'return_from_id',
            'return_from_warehouse_code',
            'return_from_warehouse_id',
            'return_from_warehouse_loc_id',
            'return_from_warehouse_name',
            'return_reason',
            'return_slip_no',
            'return_status',
            'return_to',
            'return_to_classification',
            'return_to_id',
            'return_to_warehouse_code',
            'return_to_warehouse_id',
            'return_to_warehouse_loc_id',
            'return_to_warehouse_name',
            'return_type',
            'shipment_slip_no',
            'purchase_slip_no',
            'register_pic',
            'return_from_virtual_warehouse_loc_id',
          ],
          ...params,
        },
      )
    },
    read: async (returnInstructionId: number): Promise<ReturnSearchRead> => {
      return this.api
        .post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.READ, {
          params: {
            args: [
              returnInstructionId,
              [
                'create_uid',
                'return_slip_no',
                'shipment_slip_no',
                'return_to_warehouse_id',
                'return_to_warehouse_code',
                'return_to_warehouse_name',
                'return_to_virtual_warehouse_loc_id',
                'return_from_warehouse_id',
                'return_from_warehouse_code',
                'return_from_warehouse_name',
                'return_from_classification',
                'return_from_classification',
                'return_from_virtual_warehouse_loc_id',
                'return_from_id',
                'return_to_classification',
                'return_to_id',
                'register_pic',
                'return_status',
                'return_reason',
                'return_type',
                'product_line_ids',
                'sales_slip_id',
                'return_to_warehouse_loc_id',
                'currency_id',
                'pic_id',
                'accounting_department_id',
                'coupon_ids',
                'coupon_on_subtotal',
                'employee_id',
                'purchase_slip_id',
                'return_from_warehouse_loc_id',
                'is_past_data',
                'original_sales_date',
                'original_purchase_date',
              ],
            ],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
        .then((res) => res.result[0])
        .then(async (res) => {
          if (res.product_line_ids.length > 0) {
            const products = await this._search(
              API_ROUTE.RETURN_INSTRUCTION_PRODUCT.SEARCH_READ,
              {
                domain: [['id', 'in', res.product_line_ids]],
                sort: 'product_code asc, color_sequence asc, color_code asc, size_sequence asc, size_code asc',
                context: {
                  use_ldx_custom_join: true,
                },
              },
            ).then((res) => res.result)

            return {
              ...res,
              product_line_ids: products?.records || [],
            }
          }
          return res
        })
    },
    create: (
      data: Partial<CreateReturn | CreatePurchaseReturn>,
    ): Promise<AxiosResponse<ReadResponse<number>>> => {
      return this._create<Partial<CreateReturn | CreatePurchaseReturn>>(
        API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.CREATE,
        data,
      ).then((res) => res.data)
    },
    batchCreate: (data) => {
      return this._batchCreate('warehouse.return.instruction', data)
    },
    write: (id: number, data: Partial<CreateReturn | CreatePurchaseReturn>) => {
      return this.api
        .post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.WRITE, {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
    confirm: (returnInstructionId: number) => {
      return this.api
        .post(
          API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.ACTION_MARK_RETURN_CONFIRMED,
          {
            params: {
              args: [returnInstructionId],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )
        .then((res) => res.data)
    },
    create_products: (
      data: CreateProductReturn,
    ): Promise<AxiosResponse<ReadResponse<number>>> => {
      return this._create<CreateProductReturn>(
        API_ROUTE.RETURN_INSTRUCTION_PRODUCT.CREATE,
        data,
      )
    },
    fetch_slip_no_data: (
      returnType: 'sales' | 'purchase',
      shipmentSlipNo: string,
      returnInstructionId = 0,
    ): Promise<ReadResponse<FetchSlipNoData>> => {
      return this.api
        .post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.FETCH_SLIP_NO_DATA, {
          params: {
            args: [returnInstructionId],
            kwargs: {
              return_type: returnType,
              shipment_slip_no: shipmentSlipNo,
            },
          },
        })
        .then((res) => res.data)
    },
    fetch_slip_no_product_line_data: (
      returnInstructionId: number,
      returnType: 'sales' | 'purchase',
      shipmentSlipNo: string,
      locationId: number,
      domain: any[],
    ): Promise<ReadResponse<FetchSlipNoProductLineData[]>> => {
      return this.api
        .post(
          API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION
            .FETCH_SLIP_NO_PRODUCT_LINE_DATA,
          {
            params: {
              args: [returnInstructionId],
              kwargs: {
                return_type: returnType,
                shipment_slip_no: shipmentSlipNo,
                location_id: locationId,
                domain: domain,
              },
            },
          },
        )
        .then((res) => res.data)
    },
    unlink_product: (productId: number | number[]) => {
      return this.api.post(API_ROUTE.RETURN_INSTRUCTION_PRODUCT.UNLINK, {
        params: {
          args: [productId],

          kwargs: {},
        },
      })
    },
    unlink_warehouse: (productId: number | number[]) => {
      return this.api.post(API_ROUTE.WAREHOUSE_RETURN_INSTRUCTION.UNLINK, {
        params: {
          args: [productId],

          kwargs: {},
        },
      })
    },
  }

  BudgetingHome = {
    get: (urlParams: string) => {
      return this.api.get(`/accounts/budgeting_home${urlParams}`)
    },
  }

  Forecasting = {
    get: (urlParams: string) => {
      return this.api.get(`/accounts/forecast_result${urlParams}`)
    },
  }

  BudgetingDaily = {
    get: (urlParams: string) => {
      return this.api.get(`/accounts/budgeting_daily${urlParams}`)
    },

    patch: (data: {
      daily_budget: Array<any>
      version_id: number
      forecasting_organization_id: number
      progress_month: number
      tax_ex_inclusive: string
    }) => {
      return this.api.patch('/accounts/budgeting_daily', data)
    },
  }

  BudgetingPic = {
    get: (urlParams: string) => {
      return this.api.get(`/accounts/budgeting_pic${urlParams}`)
    },

    patch: (data: {
      pic_budget: Array<any>
      version_id: number
      forecasting_organization_id: number
      progress_month: number
      tax_ex_inclusive: string
    }) => {
      return this.api.patch('/accounts/budgeting_pic', data)
    },
  }

  BudgetingPicUser = {
    get: (
      version_id: number,
      forecasting_organization_id: number,
      progress_month: number,
      user_id: number,
    ) =>
      this.api.get(
        `/accounts/budgeting_pic/user?version_id=${version_id}&forecasting_organization_id=${forecasting_organization_id}&progress_month=${progress_month}&user_id=${user_id}`,
      ),
  }

  BudgetingPicUsers = {
    get: (
      version_id: number,
      forecasting_organization_id: number,
      progress_month: number,
    ) =>
      this.api.get(
        `/accounts/budgeting_pic/users?version_id=${version_id}&forecasting_organization_id=${forecasting_organization_id}&progress_month=${progress_month}`,
      ),
  }

  Dashboard = {
    getDataDashboardCore: (version_id: number | string) =>
      this.api.get(
        `/actual/kpi_management/dashboard_core?version_id=${version_id}`,
      ),

    getDataDashboardAccount: (version_id: number | string) =>
      this.api.get(
        `/actual/kpi_management/dashboard_account?version_id=${version_id}`,
      ),
  }

  WarehouseBatchRegistration = {
    post_import_pattern: (data: any) => {
      return this.api.post(`/dataset/warehouse.master.import.sequence/create`, {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update_import_pattern_name: (id: number | string, data: any) => {
      return this.api.post(`/dataset/warehouse.master.import.sequence/write/`, {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: async (id: number) => {
      return this.api.post(
        '/dataset/warehouse.master.import.sequence/unlink/',
        {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  BatchRegistrationV2 = {
    get_import_sequence: (model: string, importSequence = 0) => {
      if (importSequence === 0) {
        return this._search(`/dataset/${model}.import.sequence/search_read/`, {
          domain: [],
        })
      }
      return this._read(
        `/dataset/${model}.import.sequence/read/`,
        importSequence,
        {
          fields: [
            'id',
            'code',
            'name',
            'type',
            'registration_operation',
            'country',
            'address',
            'post_code',
            'pic',
            'email',
            'with_inventory_control',
            'with_in_out_control',
            'with_inventory_inspection',
            // 'store',
            // 'customer',
            // 'factory',
            // 'supplier',
            'prefectures',
            'building_name',
            'allow_negative_inventory',
            'warehouse_group',
            'company_name',
            'warehouse_target_allocation',
            'warehouse_target_replenishment',
          ],

          kwargs: {},
        },
      )
    },
    get_sequences: (model: string) => {
      return this.api.post(`/dataset/batch.sequence/get_sequence`, {
        params: {
          args: [
            {
              reference_model: model,
            },
          ],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    create_import_sequence: async ({
      pattern,
      model,
    }: {
      pattern: any
      model: string
    }): Promise<{
      result: { id: number }
    }> => {
      return this.api
        .post(`/dataset/${model}.import.sequence/create`, {
          params: {
            args: [pattern],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
    update_import_sequence: async ({
      id,
      pattern,
      model,
    }: {
      id: number
      pattern: any
      model: string
    }): Promise<any> => {
      return this.api.post(`/dataset/${model}.import.sequence/write`, {
        params: {
          args: [id, pattern],

          kwargs: {},
        },
      })
    },

    implementation_history: ({
      model,
      ...params
    }: {
      model: string
    } & Omit<SearchParams, 'domain' | 'fields'>) => {
      return this._search('/dataset/batch.registration/search_read/', {
        ...params,
        domain: [['reference_model', '=', model]],
        fields: [
          'company_id',
          'pic_id',
          'filename',
          'file_url',
          'reference_model',
          'create_date',
        ],
      })
      // return this.api
      //   .get<any, AxiosResponse<ImplementationHistory>>(
      //     `/${model}/batch.registration/?limit=${limit}&offset=${offset}&sort=%22created_date%20desc%22`,
      //   )
      //   .then((res) => res.data)
    },

    universal_batch_implementation_history: ({
      domain,
      limit,
      offset,
      sort,
      fields = [
        'company_id',
        'pic_id',
        'filename',
        'file_url',
        'reference_model',
        'create_date',
        'num_of_data',
        'num_of_data_to_register',
        'num_of_data_registered',
      ],
    }: {
      domain: any[]
      limit: number
      offset: number
      sort: string
      fields?: string[]
    }) => {
      return this.api
        .post<any, AxiosResponse<ImplementationHistory>>(
          `/dataset/batch.registration/search_read`,
          {
            params: {
              fields: fields || [],
              domain,
              sort,
              limit,
              offset,
            },
          },
        )
        .then((res) => res.data)
    },

    universalUpload: ({
      model,
      file,
      sequence_id,
      lang,
      extras,
      async: isAsync,
      timezone,
      is_past_data,
      id,
    }: {
      model: string
      file: any
      sequence_id?: number
      lang?: string
      extras?: { [key: string]: any }
      async?: boolean
      timezone?: number
      is_past_data?: boolean
      id?: number
    }) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reference_model', model)
      if (sequence_id) {
        formData.append('sequence_id', sequence_id.toString())
      }
      if (extras) {
        Object.entries(extras).forEach((k) => {
          formData.append(k[0], k[1])
        })
      }
      if (lang) {
        formData.append('lang', lang)
      }
      if (id) {
        formData.append('id', id.toString())
      }
      if (typeof isAsync === 'boolean') {
        formData.append('async', isAsync as any)
      }
      if (typeof is_past_data === 'boolean') {
        formData.append('is_past_data', is_past_data as any)
      }
      if (![null, undefined].includes(timezone)) {
        formData.append('timezone', timezone as any)
      }
      return this.api.post(`/dataset/batch.registration/register`, formData, {
        timeout: 20 * 60 * 1000, // 20 menit
      })
    },

    downloadSample: ({
      reference_model,
      sequence_id = null,
      lang,
      config,
    }: {
      reference_model: string
      sequence_id?: number
      lang?: string
      config?: AxiosRequestConfig
    }) => {
      return this.api.post(
        '/dataset/batch.registration/download_excel_sequence',
        {
          params: {
            args: [{ reference_model, sequence_id, lang }],

            kwargs: {},
          },
        },
        config,
      )
    },

    upload: ({
      model,
      sequenceId,
      file,
      lang,
    }: {
      model: string
      sequenceId: number
      file: any
      lang?: string
    }) => {
      const formData = new FormData()
      formData.append('file', file)
      if (lang) {
        formData.append('lang', lang)
      }
      return this.api.post(
        `/${model}/insert_batch_registration/${sequenceId}`,
        formData,
      )
    },

    delete_sequence: (id: number, model: string) => {
      return this.api.post(`/dataset/${model}.import.sequence/unlink`, {
        params: {
          args: [[id]],

          kwargs: {},
        },
      })
    },

    BatchSequence: {
      create_sequence: ({
        name,
        reference_model,
        sequence_fields,
        type,
        checking,
      }: {
        name: string
        reference_model: string
        sequence_fields: { [key: string]: number }
        type?: string
        checking?: boolean
      }) => {
        return this.api.post(`/dataset/batch.sequence/create_sequence`, {
          params: {
            args: [
              {
                name,
                reference_model,
                sequence_fields,
                type,
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage, checking },
            },
          },
        })
      },
      write_sequence: ({
        id,
        name,
        reference_model,
        sequence_fields,
        type,
        editable_field,
        checking,
      }: {
        id: number
        name?: string
        reference_model: string
        sequence_fields?: { [key: string]: number }
        type?: string
        editable_field?: any
        checking?: boolean
      }) => {
        return this.api.post(`/dataset/batch.sequence/write_sequence`, {
          params: {
            args: [
              id,
              {
                name,
                reference_model,
                sequence_fields,
                type,
                editable_field,
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage, checking },
            },
          },
        })
      },
      downloadSample: ({
        sequence_id = null,
        reference_model,
        lang = 'en_US',
        config,
        type,
        highlight_fields,
      }: {
        sequence_id?: number
        reference_model: string
        lang?: string
        config?: AxiosRequestConfig
        type?: string
        highlight_fields?: any
      }) => {
        return this.api.post(
          `/dataset/batch.sequence/download_excel_sequence`,
          {
            params: {
              args: [
                {
                  sequence_id,
                  reference_model,
                  lang,
                  type,
                },
              ],

              kwargs: {
                context: { lang: this.currentLanguage },
                highlight_fields,
              },
            },
          },
          config,
        )
      },

      /**
       * @deprecated
       *
       * @param param0
       * @returns
       */
      get_sequence: ({
        reference_model,
        fields,
      }: {
        reference_model: string
        fields?: string[] | [string]
      }) => {
        return this.api
          .post(`/dataset/batch.sequence/get_sequence`, {
            params: {
              args: [
                {
                  reference_model,
                  fields,
                },
              ],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((r) => r.data)
      },

      /**
       * @deprecated
       *
       * @param param0
       * @returns
       */
      delete_sequence: ({ id }: { id: number }) => {
        return this.api.post(`/dataset/batch.sequence/unlink`, {
          params: {
            args: [id],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
      },
    },
  }

  TradingExpense = {
    create: (data: TradingExpenseData) => {
      return this.api.post('/dataset/trading.expense/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: ({ id, ...data }: TradingExpenseData & { id: number }) => {
      return this.api.post('/dataset/trading.expense/write', {
        params: {
          args: [id, data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    delete: (ids: number[]) => {
      return this.api.post('/dataset/trading.expense/soft_unlink', {
        params: {
          args: [ids],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/trading.expense/unarchives', {
        params: {
          args: [ids],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    confirm: (id: number) => {
      return this.api.post('/dataset/trading.expense/action_post', {
        params: {
          args: [id],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  TradingExpenseLine = {
    create: (data) => {
      return this.api.post('/dataset/trading.expense.lines/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }

  MaterialTypeMaster = {
    create: (data: any) => {
      return this.api.post('/dataset/material_master.type/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    update: (id: any, data: any) => {
      return this.api.post('/dataset/material_master.type/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (ids: any) => {
      return this.api.post('/dataset/material_master.type/unlink', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
  }

  ProductionControlImportSequences = this._generateCrud(
    'production.control.import.sequence',
  )

  ProductionControlBatch = {
    upload: (id: number, file: any) => {
      const formData = new FormData()
      formData.append('file', file)

      return this.api.post(
        `/production.control/insert_batch_registration/${id}`,
        formData,
      )
    },
  }

  AccountPayable = {
    search_read: (params?: SearchRead) => {
      return this._search(API_ROUTE.ACCOUNT_MOVE.SEARCH_READ, params)
    },
    read: (accountPayableId: number) => {
      return this.api
        .post(API_ROUTE.ACCOUNT_MOVE.READ, {
          params: {
            args: [accountPayableId],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
    write: ({ accountPayableId, ...params }: UpdateAccountPayableProps) => {
      return this.api
        .post(API_ROUTE.ACCOUNT_MOVE.WRITE, {
          params: {
            args: [accountPayableId, params],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },

    AccountPayableLog: {
      ...this._generateCrud('account.move.list.log'),
    },

    AccountPayableLine: {
      ...this._generateCrud('account.move.list'),
      registerInvoicePayment: ({
        async,
        ...params
      }: PaymentRegistrationFormData & { async?: boolean }) =>
        this.api.post(API_ROUTE.ACCOUNT_MOVE_LIST.REGISTER_INVOICE_PAYMENT, {
          params: {
            args: [params],
            kwargs: {
              context: {
                async,
              },
            },
          },
        }),
      cancelInvoicePayment: (params: PaymentCancelInvoiceFormData) =>
        this.api.post(API_ROUTE.ACCOUNT_MOVE_LIST.CANCEL_INVOICE_PAYMENT, {
          params: {
            args: [params],
            kwargs: {},
          },
        }),
      generateBilling: (params: {
        account_move_ids: number[]
        billing_date: string
        currency_id: number
        partner_id: number
      }) => {
        return this.api.post(API_ROUTE.ACCOUNT_MOVE_LIST.GENERATE_BILLING, {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {},
          },
        })
      },
      generatePreviewBilling: (params: {
        billing_date: string
        billing_to_master_id: number
        currency_id: number
        move_ids: any[]
        is_summary_only?: boolean
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.GENERATE_BILLING_DICT,
          {
            params: {
              args: [params],
              kwargs: {},
            },
            kwargs: {
              context: {
                lang: this.currentLanguage,
                is_summary_only: params?.is_summary_only || false,
              },
            },
          },
        )
      },
      prepareBatchBilling: ({
        billing_invoice_date,
        currency_id,
        generate_by,
        use_queue_job,
        preview_mode,
        is_auto_confirm_billing,
        allowed_classification,
        is_billing_merge,
        invoice_type_combine,
        invoice_type_separate,
        data_sort_key,
        data_sort_order,
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.BATCH_PREPARE_BILLING,
          {
            params: {
              args: [
                {
                  billing_invoice_date,
                  currency_id,
                  generate_by,
                  preview_mode,
                  allowed_classification,
                  is_billing_merge,
                  invoice_type_combine,
                  invoice_type_separate,
                  data_sort_key,
                  data_sort_order,
                },
              ],
              kwargs: {
                context: {
                  use_queue_job,
                  lang: this.currentLanguage,
                  tz: getLocalTimeZoneName(),
                  is_auto_confirm_billing,
                },
              },
            },
          },
        )
      },
      batchPreparePaymentStatement: ({
        date,
        currency_id,
        generate_by,
        use_queue_job = true,
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.BATCH_PREPARE_PAYMENT_STATEMENT,
          {
            params: {
              args: [
                {
                  date,
                  currency_id,
                  generate_by,
                },
              ],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                  tz: getLocalTimeZoneName(),
                  use_queue_job,
                },
              },
            },
          },
        )
      },
      createPaymentStatement: (ids: number[]) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.CREATE_PAYMENT_STATEMENT,
          {
            params: {
              args: [ids],
              kwargs: { context: { lang: this.currentLanguage } },
            },
          },
        )
      },
      searchReadPaymentSchedule: (args: any) => {
        return this.api
          .post(API_ROUTE.ACCOUNT_MOVE_LIST.SEARCH_READ_PAYMENT_SCHEDULE, {
            params: {
              args: [args],
              kwargs: { context: { lang: this.currentLanguage } },
            },
          })
          .then((resp) => {
            return resp.data
          })
      },
      searchReadPaymentScheduleLine: (args: any) => {
        return this.api
          .post(API_ROUTE.ACCOUNT_MOVE_LIST.SEARCH_READ_PAYMENT_SCHEDULE_LINE, {
            params: {
              args: [args],
              kwargs: { context: { lang: this.currentLanguage } },
            },
          })
          .then((resp) => {
            return resp.data
          })
      },
      downloadExcel: ({
        headers,
        access_time,
        convert_fields,
        domain,
        currency_fields,
        date_fields,
        move_category,
        ratio_fields,
        zero_cumulative_balance,
        method,
        start_date,
        end_date,
        is_display_consumer,
      }: {
        headers: any[]
        access_time: string
        convert_fields: any
        currency_fields: string[]
        zero_cumulative_balance?: boolean
        date_fields: string[]
        start_date?: string
        end_date?: string
        domain: any
        move_category: 'sales' | 'purchase'
        ratio_fields?: any[]
        is_display_consumer?: boolean
        method:
          | 'search_read_by_group'
          | 'export_excel_linked'
          | 'search_read_payment_schedule'
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.DOWNLOAD_EXCEL,
          {
            params: {
              args: [],
              kwargs: {
                headers,
                convert_fields,
                date_fields,
                currency_fields,
                ratio_fields,
                method,
                method_args: [
                  {
                    domain: domain,
                    sort: 'id desc',
                    access_time,
                    move_category,
                    zero_cumulative_balance,
                    start_date,
                    end_date,
                    is_display_consumer,
                  },
                ],
                lang: this.currentLanguage,
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
      },
      downloadExcelLinked: ({
        headers,
        convert_fields,
        domain,
        currency_fields,
        date_fields,
        classification,
        ratio_fields,
        name,
      }: {
        headers: any[]
        name: string
        convert_fields?: any
        currency_fields?: string[]
        date_fields?: string[]
        domain: any
        classification: 'account_receivable' | 'account_payable'
        ratio_fields?: any[]
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.DOWNLOAD_EXCEL_LINKED,
          {
            params: {
              args: [
                {
                  classification,
                  domain,
                  name,
                  format: {
                    headers,
                    convert_fields,
                    date_fields,
                    currency_fields,
                    ratio_fields,
                  },
                  sort: 'id desc',
                },
              ],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                  tz: getLocalTimeZoneName(),
                },
              },
            },
          },
        )
      },
      downloadExcelPaymentSchedule: ({
        display_method,
        domain,
        access_time,
      }: {
        display_method: 'supplier' | 'payment_plan_date'
        domain: any[]
        access_time: string
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.DOWNLOAD_EXCEL_PAYMENT_SCHEDULE,
          {
            params: {
              args: [],
              kwargs: {
                lang: i18n?.language?.includes('en') ? 'en_US' : 'ja_JP',
                display_method,
                access_time,
                domain,
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
      },
      exportExcel: ({
        headers,
        access_time,
        convert_fields,
        domain,
        currency_fields,
        date_fields,
        display_method,
      }: {
        headers: any[]
        access_time: string
        convert_fields: any
        currency_fields: string[]
        date_fields: string[]
        domain: any
        display_method: 'supplier' | 'payment_plan_date'
      }) => {
        return this.api.post(
          API_ROUTE.ACCOUNT_MOVE_LIST.EXPORT_EXCEL,
          {
            params: {
              args: [
                {
                  domain,
                  sort: 'id desc',
                  display_method,
                  move_category: 'sales',
                  access_time,
                },
                {
                  headers,
                  convert_fields,
                  date_fields,
                  currency_fields,
                  lang: i18n?.language?.includes('en') ? 'en_US' : 'ja_JP',
                },
              ],
              kwargs: {},
            },
          },
          {
            responseType: 'blob',
          },
        )
      },
    },
    CreateTaxAdjustment: (params: TaxAdjustmentFormData) =>
      this.api.post(API_ROUTE.ACCOUNT_MOVE.CREATE_ADJUSTMENT, {
        params: {
          args: [params],
          kwargs: {},
        },
      }),
    CreateTaxAdjustmentMulti: (params: TaxAdjustmentFormData[]) =>
      this.api.post(API_ROUTE.ACCOUNT_MOVE.CREATE_ADJUSTMENT_MULTI, {
        params: {
          args: [params],
          kwargs: {},
        },
      }),
    UnlinkTaxAdjusment: (params: number[]) =>
      this.api.post(API_ROUTE.ACCOUNT_MOVE.UNLINK_ADJUSTMENT, {
        params: {
          args: [
            {
              adjustment_ids: params,
            },
          ],
          kwargs: {},
        },
      }),
    UploadInvoice: (params: InvoiceUploadFormData) => {
      const formData = new FormData()
      formData.append('invoice_file', params.invoice_file)
      formData.append(
        'invoice_payment_id',
        params.invoice_payment_id.toString(),
      )

      return this.api.post('/invoice/upload/', formData)
    },
  }

  AccountPayableLine = {
    search_read: (params?: SearchRead) => {
      return this._search<AccountPayableLineType[]>(
        '/dataset/account.move.ap.line/search_read',
        params,
      )
    },
    read: (accountPayableId: number | number[]) => {
      return this.api
        .post('/dataset/account.move.ap.line/read/', {
          params: {
            args: [accountPayableId],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
  }

  AccountReceivable = {
    search_read: (params?: SearchRead) => {
      return this._search('/dataset/account.move/search_read', params)
    },
    read: (accountReceivableId: number, context?: Record<string, string>) => {
      return this.api
        .post('/dataset/account.move/read/', {
          params: {
            args: [accountReceivableId],
            kwargs: {
              context: {
                ...context,
              },
            },
          },
        })
        .then((res) => res.data)
    },
    write: ({
      accountReceivableId,
      ...params
    }: UpdateAccountReceivableProps) => {
      return this.api
        .post('/dataset/account.move/write/', {
          params: {
            args: [accountReceivableId, params],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },

    Line: this._generateCrud('account.move.list'),
    StopShipping: (accountMoveId: number[]) =>
      this.api.post(API_ROUTE.ACCOUNT_MOVE_LIST.ACTION_MARK_SUSPENSION, {
        params: {
          args: [accountMoveId],
          kwargs: {},
        },
      }),
  }

  AccountReceivableLine = {
    search_read: (params?: SearchRead) => {
      return this._search('/dataset/account.move.ar.line/search_read', params)
    },
    read: (accountReceivableId: number | number[]) => {
      return this.api
        .post('/dataset/account.move.ar.line/read/', {
          params: {
            args: [accountReceivableId],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
  }

  OrganizationMaster = {
    ...this._generateCrud('organization.master'),
    create: (data: OrganizationMasterData) => {
      return this.api.post('/dataset/organization.master/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    write: ({ id, ...data }: OrganizationMasterData) => {
      return this.api.post('/dataset/organization.master/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (id: number) => {
      return this.api.post('/dataset/organization.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  BillingToMaster = {
    ...this._generateCrud('billing.to.master'),
    create: (data: Partial<BillingToMasterData>) => {
      return this.api.post('/dataset/billing.to.master/create', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    write: ({ id, ...data }: Partial<BillingToMasterData>) => {
      return this.api.post('/dataset/billing.to.master/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    getPaymentDueDate: ({ id, ...data }: Partial<BillingToMasterData>) => {
      return this.api.post('/dataset/billing.to.master/get_payment_due_date', {
        params: {
          args: [id, data],
          kwargs: {},
        },
      })
    },

    delete: (id: number) => {
      return this.api.post('/dataset/billing.to.master/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    read: (billingToId: number | number[], fields: string[] = []) => {
      return this.api
        .post('/dataset/billing.to.master/read/', {
          params: {
            args: [billingToId, fields],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
    },
    ReleaseSuspend: (billingToMasterId: number) => {
      return this.api.post(
        '/dataset/billing.to.master/action_release_suspend/',
        {
          params: {
            args: [billingToMasterId],
            kwargs: {},
          },
        },
      )
    },

    CustomerLine: {
      search_read: (params?: SearchRead) => {
        return this._search(
          '/dataset/billing.to.master.customer.line/search_read/',
          params,
        )
      },
      read: (customerLineId: number | number[]) => {
        return this.api
          .post('/dataset/billing.to.master.customer.line/read/', {
            params: {
              args: [customerLineId],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      delete: (customerLineId: number | number[]) => {
        return this.api.post(
          '/dataset/billing.to.master.customer.line/unlink/',
          {
            params: {
              args: [customerLineId],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )
      },
    },

    StoreLine: {
      search_read: (params?: SearchRead) => {
        return this._search(
          '/dataset/billing.to.master.store.line/search_read/',
          params,
        )
      },
      read: (storeLineId: number | number[]) => {
        return this.api
          .post('/dataset/billing.to.master.store.line/read/', {
            params: {
              args: [storeLineId],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      delete: (storeLineId: number | number[]) => {
        return this.api.post('/dataset/billing.to.master.store.line/unlink/', {
          params: {
            args: [storeLineId],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
      },
    },

    ClosingDateLine: {
      search_read: (params?: SearchRead) => {
        return this._search(
          '/dataset/billing.to.master.closing.date/search_read/',
          params,
        )
      },
    },
  }

  CustomerMaster = {
    ...this._generateCrud('res.partner'),
    isConsignmentExist: (id: number) => {
      return this.api.post(
        '/dataset/res.partner/is_consignment_transaction_exist',
        {
          params: {
            args: [id],

            kwargs: {},
          },
        },
      )
    },
    delete: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/unlink', {
        params: {
          args: [ids],

          kwargs: {},
        },
      })
    },
    softDelete: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/soft_unlink', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
    restore: (ids: number[]) => {
      return this.api.post('/dataset/res.partner/unarchives', {
        params: {
          args: ids,

          kwargs: {},
        },
      })
    },
  }

  EachProductReplenishmentSetting = {
    create: (data: Partial<EachProductReplenishmentSetting>) => {
      return this.api.post('/dataset/product.replenishment.template/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    write: ({ id, ...data }: Partial<EachProductReplenishmentSetting>) => {
      return this.api.post('/dataset/product.replenishment.template/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    batch_write: (data: Partial<EachProductReplenishmentSetting>[]) => {
      return this.api.post(
        '/dataset/product.replenishment.template/batch_write',
        {
          params: {
            args: [data.map((x) => [x.id, omit(x, ['id'])])],

            kwargs: {},
          },
        },
      )
    },
    downloadAll: (params?: any) => {
      return this.api.post(
        '/dataset/product.replenishment.setting/download_all',
        {
          params: {
            args: [params],
            kwargs: {},
          },
        },
      )
    },
  }

  EachStoreReplenishmentSetting = {
    create: (data: any) => {
      return this.api.post('/dataset/store.replenishment.setting/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    write: ({ id, ...data }: Partial<EachProductReplenishmentSetting>) => {
      return this.api.post('/dataset/store.replenishment.setting/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    batch_write: (args: Partial<EachProductReplenishmentSetting>[]) => {
      return this.api.post('/dataset/store.replenishment.setting/batch_write', {
        params: {
          args: [args.map((x) => [x.id, omit(x, ['id'])])],

          kwargs: {},
        },
      })
    },
    writeSafety: ({ id, ...data }: any) => {
      return this.api.post(
        '/dataset/store.replenishment.disgestion.setting/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    writeAutoFrequency: ({ id, ...data }: any) => {
      return this.api.post(
        `/store.replenishment.auto.frequency.setting/${id}/write`,
        data,
        {
          headers: {
            'content-type': 'text/plain',
          },
        },
      )
    },
  }

  EachStoreProductReplenishmentSetting = {
    ...this._generateCrud('store.product.replenishment.setting'),
    searchByProducts: (params?: any) => {
      return this.api
        .post(
          '/dataset/store.product.replenishment.setting/custom_search_read',
          {
            params: {
              args: [],
              kwargs: {
                context: { lang: this.currentLanguage },
                ...(params ?? {}),
              },
            },
          },
        )
        .then((resp) => resp.data)
    },
    downloadAll: (params?: any) => {
      return this.api.post(
        '/dataset/store.product.replenishment.setting/download_all',
        {
          params: {
            args: [params],
            kwargs: {},
          },
        },
      )
    },
  }

  OrganizationSettingDev2 = {
    getOption: (isBudget = false) =>
      this.api.get(
        `/accounts/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }/all_options`,
      ),

    getBudgetAndVersion: () => this.api.get('/actual/budgets_versions'),

    getOrganization: (isBudget = false) =>
      this.api.get(
        `actual/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }?budget_id=null`,
      ),

    getTreeSelect: (isBudget = false) =>
      this.api.get(
        `/actual/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }?treenode=true&version_id=null`,
      ),

    register: (data: any, isBudget = false) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post(
        `/actual/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }`,
        formData,
      )
    },

    update: (datas: any, isBudget = false) => {
      const { organization_id, ...data } = datas
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.patch(
        `/actual/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }/${organization_id}`,
        formData,
      )
    },

    delete: (id: any, lang: 'ja_JP' | 'en_US', isBudget = false) =>
      this.api.post(
        `/dataset/${
          isBudget ? 'forecasting.organization.master' : 'organization.master'
        }/unlink`,
        {
          params: {
            args: [id],

            kwargs: {},
            context: {
              lang,
            },
          },
        },
      ),

    create: (
      data: { forecasting_organization_budget_id: any; data: any },
      isBudget = false,
    ) => {
      return this.api.post(
        `/accounts/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }/batch_create`,
        data,
      )
    },

    import: (
      data: { forecasting_organization_budget_id: any; file: any },
      isBudget = false,
    ) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post(
        `/accounts/${
          isBudget ? 'forecasting_organization_master' : 'organization_master'
        }/import`,
        formData,
      )
    },
    downloadExcel: (
      {
        headers,
        convert_fields = [],
        domain = [],
        currency_fields = [],
        date_fields = [],
        ratio_fields = [],
      }: {
        headers: any[]
        convert_fields?: any
        currency_fields?: string[]
        date_fields?: string[]
        domain?: any
        ratio_fields?: any[]
      },
      isBudget = false,
    ) => {
      return this.api.post(
        `/dataset/${
          isBudget ? 'forecasting.organization.master' : 'organization.master'
        }/download_excel`,
        {
          params: {
            args: [],
            kwargs: {
              headers,
              convert_fields,
              date_fields,
              currency_fields,
              ratio_fields,
              domain,
              lang: this.currentLanguage,
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
  }

  MDPlan = {
    SimpleStoreGradeSetting: {
      get_datas: (filter?: Record<string, unknown>) => {
        return this.api
          .post(API_ROUTE.MD_STORE_RANK_SETTING.GET_DATAS, {
            params: {
              args: [0],
              kwargs: {
                ...filter,
                context: {
                  tz: getLocalTimeZoneName(),
                },
              },
            },
          })
          .then((res) => res.data)
      },
      search_read: (params?: SearchRead) => {
        return this._search(API_ROUTE.MD_STORE_RANK_SETTING.SEARCH_READ, params)
      },
      create: (
        params:
          | SimpleStoreGradeSettingFormData
          | SimpleStoreGradeSettingFormData[],
      ) => {
        return this.api
          .post(API_ROUTE.MD_STORE_RANK_SETTING.CREATE, {
            params: {
              args: [params],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      write: (
        storeRankSettingId: number,
        params:
          | SimpleStoreGradeSettingFormData
          | SimpleStoreGradeSettingFormData[],
      ) => {
        return this.api
          .post(API_ROUTE.MD_STORE_RANK_SETTING.WRITE, {
            params: {
              args: [storeRankSettingId, params],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      unlink: (storeRankSettingIds: number[]) => {
        return this.api
          .post(API_ROUTE.MD_STORE_RANK_SETTING.UNLINK, {
            params: {
              args: [storeRankSettingIds],
              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      upload: (file: File, setting_id: number) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('setting_id', setting_id.toString())
        return this.api.post('/store.rank.setting/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      },
    },
    OrderPatternBySize: {
      get_datas: (
        filter?: Record<string, unknown>,
      ): Promise<ReadResponse<OrderPatternBySizeResult[]>> => {
        return this.api
          .post('/dataset/order.pattern.size/get_datas/', {
            params: {
              args: [0],
              kwargs: {
                ...filter,
                context: {
                  tz: getLocalTimeZoneName(),
                },
              },
            },
          })
          .then((res) => res.data)
      },
      create: (
        params: OrderPatternBySizeFormData | OrderPatternBySizeFormData[],
      ) => {
        return this.api.post('/dataset/order.pattern.size/create/', {
          params: {
            args: [params],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
      },
      bulkUpdate: ({ data, kwargs = {} }: { data: any; kwargs: any }) => {
        return this.api.post('/dataset/order.pattern.size/bulk_write/', {
          params: {
            args: [data],
            kwargs,
          },
        })
      },
      write: (
        id: number,
        params: OrderPatternBySizeFormData | OrderPatternBySizeFormData[],
      ) => {
        return this.api
          .post('/dataset/order.pattern.size/write/', {
            params: {
              args: [id, params],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
    },
  }

  MDPlanSetting = {
    create: (data: any) => {
      return this.api.post('/dataset/md.plan.setting/create', {
        params: {
          args: [data],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    update: (data: any) => {
      return this.api.post('/dataset/md.plan.setting/write', {
        params: {
          args: data,

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write: (id: number, data: any) => {
      return this.api.post('/dataset/md.plan.setting/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    delete: (data: any) => {
      return this.api.post('/dataset/md.plan.setting/unlink', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
  }

  ProperPeriodSetting = {
    update: ({ id, ...data }) => {
      return this.api.post(
        '/dataset/md.season.period.setting.specification/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    create: (data) => {
      return this.api.post(
        '/dataset/md.season.period.setting.specification/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    bulk_write: ({
      setting_id,
      payload,
    }: {
      setting_id: number
      payload: any[]
    }) => {
      return this.api.post(
        '/dataset/md.season.period.setting.specification/bulk_write',
        {
          params: {
            args: [],
            kwargs: {
              setting_id,
              payload,
            },
          },
        },
      )
    },
    write_all: ({
      setting_id,
      payload,
    }: {
      setting_id: number
      payload: any
    }) => {
      return this.api.post(
        '/dataset/md.season.period.setting.specification/write_all',
        {
          params: {
            args: [],
            kwargs: {
              setting_id,
              payload,
            },
          },
        },
      )
    },
  }

  MDMonthlySalesPlan = {
    update: ({ id, ...data }) => {
      return this.api.post(
        '/dataset/md.monthly.sales.plan.specification/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    create: (data) => {
      return this.api.post(
        '/dataset/md.monthly.sales.plan.specification/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    bulk_write: (data: { write_payload: any[]; create_payload: any[] }) => {
      return this.api.post(
        '/dataset/md.monthly.sales.plan.specification/bulk_write',
        {
          params: {
            args: [],

            kwargs: {
              ...data,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    write_all: (data: { setting_id: number; payload: any }) => {
      return this.api.post(
        '/dataset/md.monthly.sales.plan.specification/write_all',
        {
          params: {
            args: [],

            kwargs: {
              ...data,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  ProperDigestionRatio = {
    update: ({ id, ...data }) => {
      return this.api.post(
        '/dataset/md.season.proper_digestion_ratio.setting/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    create: (data) => {
      return this.api.post(
        '/dataset/md.season.proper_digestion_ratio.setting/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    bulk_write: (data: { write_payload: any[]; create_payload: any[] }) => {
      return this.api.post(
        '/dataset/md.season.proper_digestion_ratio.setting/bulk_write',
        {
          params: {
            args: [],

            kwargs: {
              ...data,
              // context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    write_all: (data: { setting_id: number; payload: any }) => {
      return this.api.post(
        '/dataset/md.season.proper_digestion_ratio.setting/write_all',
        {
          params: {
            args: [],

            kwargs: {
              ...data,
              // context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    upload: (form: FormData) => {
      return this.api.post(
        `/md_plan/md_season_proper_digestion_ratio_setting/upload`,
        form,
      )
    },
  }

  MDProductDiscountSetting = {
    update: ({ id, ...data }) => {
      return this.api.post(
        '/dataset/md.season.product_new.discount.setting/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    create: (data) => {
      return this.api.post(
        '/dataset/md.season.product_new.discount.setting/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    upload: (form: FormData) => {
      return this.api.post(
        `/md_plan/md_season_product_new_discount_setting/upload`,
        form,
      )
    },
    bulk_write: ({
      create_payload,
      write_payload,
    }: {
      create_payload: any[]
      write_payload: any[]
    }) => {
      return this.api.post(
        '/dataset/md.season.product_new.discount.setting/bulk_write',
        {
          params: {
            args: [],
            kwargs: {
              create_payload,
              write_payload,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  MDProductContinuesDiscountSetting = {
    update: ({ id, ...data }) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/write',
        {
          params: {
            args: [id, data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    create: (data) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    upload: (form: FormData) => {
      return this.api.post(
        `/md_plan/md_season_product_continues_discount_setting/upload`,
        form,
      )
    },

    lastSeasonActual: (args?: {
      limit?: number
      offset?: number
      setting_id?: number
    }) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/data_read_actual',
        {
          params: {
            args: [args],
            kwargs: {
              context: {
                tz: getLocalTimeZoneName(),
              },
            },
          },
        },
      )
    },
    bulk_write: ({
      create_payload,
      write_payload,
    }: {
      create_payload: any[]
      write_payload: any[]
    }) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/bulk_write',
        {
          params: {
            args: [],
            kwargs: {
              create_payload,
              write_payload,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    write_all: ({
      setting_id,
      payload,
    }: {
      setting_id: number
      payload: any
    }) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/write_all',
        {
          params: {
            args: [],
            kwargs: {
              setting_id,
              payload,
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  MDProductPurchasePlan = {
    update: ({ id, ...data }) => {
      return this.api.post('/dataset/md.season.purchase.plan.category/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    create: (data) => {
      return this.api.post('/dataset/md.season.purchase.plan.category/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    bulk_write: (data: {
      create_payload: any[]
      write_parent_payload: any[]
      write_line_payload: any[]
    }) => {
      return this.api.post(
        '/dataset/md.season.purchase.plan.category/bulk_write',
        {
          params: {
            args: [],
            kwargs: data,
          },
        },
      )
    },
  }

  MDContinuationProductRotationRate = {
    create: (data) => {
      return this.api.post(
        '/dataset/md.season.product_continues.discount.setting/create',
        {
          params: {
            args: [data],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
  }

  SettingLaborCost = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/laborcosts_predict_transactions${urlParams}`),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_salesagency_configuration_predict_transaction: any
      draft_salesagency_salesratio_predict_transaction: Array<any>
      draft_laborcosts_predict_transaction: Array<any>
      draft_recruitment_welfare_predict_transaction: any
    }) => this.api.post('/accounts/laborcosts_predict_transactions', data),

    registerManpower: (data: {
      version_id: any
      forecasting_organization_id: any
      manpower_predict_transactions: any
    }) => this.api.post('/accounts/manpower_predict_transactions', data),
  }

  SettingOtherCost = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/other_expenses_predict_transactions${urlParams}`),

    gateway: (datas: {
      version_id: number
      forecasting_organization_id: number
      gateway_accounttitle_submaster_id: number
      gateway_electronic_payment_rate: number
      gateway_applicable_fee_rate: number
    }) => this.api.post('/accounts/other_predict_transactions/gateway', datas),

    promotion: (datas: {
      version_id: number
      forecasting_organization_id: number
      promotion_accounttitle_submaster_id: number
      promotion_calc_methods: number
      promotion_fixedamount: number
    }) =>
      this.api.post('/accounts/other_predict_transactions/promotion', datas),

    logistics: (datas: {
      version_id: number
      forecasting_organization_id: number
      logistics_fixed_accounttitle_submaster_id: number
      logistics_fixed: number
      logistics_salesratio_accounttitle_submaster_id: number
      logistics_salesratio: number
    }) =>
      this.api.post('/accounts/other_predict_transactions/logistics', datas),

    others: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_other_expenses_predict_transaction: Array<any>
    }) => {
      return this.api.post(
        '/accounts/other_expenses_predict_transactions',
        data,
      )
    },

    spreadsheet_predict_transaction: (data: {
      version_id: number
      forecasting_organization_id: number
      spreadsheet_predict_transaction: Array<any>
      actionelement_codes: Array<string>
    }) => {
      return this.api.post('/accounts/spreadsheet_predict_transaction', data)
    },
  }

  SettingOperating = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/operating_predict_transactions${urlParams}`),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_operating_predict_transaction: Array<any>
    }) => this.api.post('/accounts/operating_predict_transactions', data),
  }

  SettingDepreciation = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/depreciation_predict_transactions${urlParams}`),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_depreciation_predict_transaction: Array<any>
    }) => this.api.post('/accounts/depreciation_predict_transactions', data),
  }

  LandrentSetting = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/landrent_predict_transactions${urlParams}`),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_landrent_predict_transaction: Array<any>
    }) => this.api.post('/accounts/landrent_predict_transactions', data),
  }

  SalesSettingLicense = {
    get: (urlParams: string) =>
      this.api.get(
        `/accounts/license_salesagency_predict_transactions${urlParams}`,
      ),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      draft_license_salesagency_minimum_predict_transaction: Array<any>
      draft_license_salesagency_addition_predict_transaction: Array<any>
    }) =>
      this.api.post('/accounts/license_salesagency_predict_transactions', data),
  }

  SalesSetting = {
    get: (urlParams: string) =>
      this.api.get(`/accounts/sales_costs_predict_transactions${urlParams}`),

    post: (data: {
      version_id: number
      forecasting_organization_id: number
      spreadsheet_predict_transaction: Array<any>
      actionelement_codes: Array<string>
    }) => this.api.post('/accounts/sales_costs_predict_transactions', data),
  }

  Version = {
    get: (budget_id) =>
      this.api.get(`/accounts/versions?budget_id=${budget_id}`),

    create: (data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post('/accounts/versions', formData)
    },

    update: (id: string | number, data: any) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.patch(`/accounts/versions/${id}`, formData)
    },

    delete: (id: string | number) =>
      this.api.delete(`/accounts/versions/${id}`),
  }

  ProfitAndLoss = {
    get: (urlParams: string) => {
      return this.api.get(`/accounts/profit_and_loss${urlParams}`)
    },
  }

  PerformanceForecast = {
    getTotalRevenue: (budger_id: number | string) => {
      return this.api.get(
        `/actual/predict_managements/net_sales/report?budget_id=${budger_id}`,
      )
    },

    getEvaluateTotalRevenue: (version_id: number | string) => {
      return this.api.get(
        `/actual/predict_managements/evaluate_net_sales/report?version_id=${version_id}`,
      )
    },

    getBusinessProfit: (budger_id: number | string) => {
      return this.api.get(
        `/actual/predict_managements/business_profit/report?budget_id=${budger_id}`,
      )
    },

    getEvaluateBusinessProfit: (version_id: number | string) => {
      return this.api.get(
        `/actual/predict_managements/evaluate_business_profit/report?version_id=${version_id}`,
      )
    },
  }

  CompanyForecastAndResults = {
    get: (forecasting_organization_id: number | string) =>
      this.api.get(
        `/actual/predict_managements/budget_achievement/report?forecasting_organization_id=${forecasting_organization_id}`,
      ),
  }

  MDNumberPlanning = {
    update: ({ id, ...data }) => {
      return this.api.post('/dataset/md.season.price.range/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },
    create: (data) => {
      return this.api.post('/dataset/md.season.price.range/create', {
        params: {
          args: [data],

          kwargs: {},
        },
      })
    },
    bulk_write: (data: { write_payload: any[]; create_payload: any[] }) => {
      return this.api.post('/dataset/md.season.price.range/bulk_write', {
        params: {
          args: [],

          kwargs: data,
        },
      })
    },
    applyMDMap: ({
      settingId,
      rangeSetting,
    }: {
      settingId: number
      rangeSetting: number
    }) => {
      return this.api.post('/dataset/md.season.price.range/apply_md_map', {
        params: {
          args: [],

          kwargs: {
            setting_id: settingId,
            range_setting: rangeSetting,
          },
        },
      })
    },
    productDetail: (ids: number[]): Promise<{ data: { result: any } }> => {
      return this.api.post(
        '/dataset/md.season.price.range/get_product_details',
        {
          params: {
            args: [''],
            kwargs: {
              product_ids: ids,
            },
          },
        },
      )
    },
  }

  MDTurnOverRatio = {
    update: ({ id, ...data }) => {
      return this.api.post('/dataset/md.season.turnover.setting/write', {
        params: {
          args: [id, data],

          kwargs: {},
        },
      })
    },

    upload: (form: FormData) => {
      return this.api.post(`/md_plan/md_season_turnover_setting/upload`, form)
    },
    create: (data) => {
      return this.api.post('/dataset/md.season.turnover.setting/create', {
        params: {
          args: data,

          kwargs: {},
        },
      })
    },
    bulkWrite: (settingId, data) => {
      return this.api.post('/dataset/md.season.turnover.setting/bulk_write', {
        params: {
          args: [],

          kwargs: { payload: data, setting_id: settingId },
        },
      })
    },
    write_all: (data: { setting_id: number; payload: any }) => {
      return this.api.post('/dataset/md.season.turnover.setting/write_all', {
        params: {
          args: [data.setting_id, { ...data.payload }],
          kwargs: {},
        },
      })
    },

    compute: (data: { setting_id: number }) => {
      return this.api.post(
        '/dataset/md.season.turnover.setting/compute_all_data',
        {
          params: {
            args: [{ ...data }],
            kwargs: {
              context: { tz: getLocalTimeZoneName() },
            },
          },
        },
      )
    },

    computeStatus: (data: { setting_id: number }) => {
      return this.api.post(
        '/dataset/md.season.turnover.setting/read_compute_all_status',
        {
          params: {
            args: [data.setting_id],
            kwargs: {
              context: { tz: getLocalTimeZoneName() },
            },
          },
        },
      )
    },
  }

  SalesCompositionRatioAndDiscountRateSetting = {
    import: (data: {
      file: any
      version_id: number
      forecasting_organization_id: number
    }) => {
      const formData = new FormData()
      Object.entries(data).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      return this.api.post(
        '/accounts/ratio_predict_transaction/import',
        formData,
      )
    },
  }

  ProductAnalysis = {
    searchProductsIds: ({
      domain,
      limit,
      offset,
      order,
    }: {
      domain?: any[]
      limit?: number | boolean
      offset?: number | boolean
      order?: any
    } = {}) => {
      return this.api.post('/dataset/product.product/search', {
        params: {
          args: [domain],
          kwargs: {
            limit,
            offset,
            order,
          },
        },
      })
    },
    searchProducts: ({
      domain,
      limit,
      offset,
      sort,
      fields,
    }: {
      domain?: any[]
      limit?: number
      offset?: number
      sort?: any
      fields?: string[]
    } = {}) => {
      return this.api.post('/dataset/product.product/search_read', {
        params: {
          domain,
          fields,
          limit: limit || false,
          offset: offset || false,
          sort: sort || false,
        },
      })
    },
    getProductsAsGrouping: ({
      fields,
      kwargs,
      domain,
      notNullFields,
      excludeGroupbyFields,
    }: {
      fields?: string[]
      kwargs?: any
      domain?: any
      notNullFields?: string[]
      excludeGroupbyFields?: string[]
    } = {}) => {
      return this.api.post('/in_season_analysis/products', {
        params: {
          fields,
          kwargs,
          domain,
          not_null_fields: notNullFields,
          exclude_groupby_fields: excludeGroupbyFields,
        },
      })
    },
    overall: (
      data: {
        season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        sales_channel_id?: number
        product_ids: number[]
        domain?: any[]
        fields?: string[]
        from?: string
      },
      setCancel?,
    ) => {
      const { CancelToken } = axios
      return this.api.post('/in_season_analysis/overall', data, {
        cancelToken: new CancelToken((cancel) => {
          if (setCancel) setCancel((prev) => ({ ...prev, cancel }))
        }),
      })
    },
    color_query: (
      data: {
        season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        sales_channel_id?: number
        product_ids: number[]
        domain?: any[]
        fields?: string[]
        from?: string
      },
      setCancel?,
    ) => {
      const { CancelToken } = axios
      return this.api.post(
        '/in_season_analysis/color_query',
        {
          ...data,
          context: {
            lang: this.currentLanguage,
            tz: getLocalTimeZoneName(),
          },
        },
        {
          cancelToken: new CancelToken((cancel) => {
            if (setCancel) setCancel((prev) => ({ ...prev, cancel }))
          }),
        },
      )
    },
    getEachProductCalculationAnalysis: (
      params: SearchParams & {
        display_season_id?: number
        comparison_season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        md_season_id?: number
        customer_domain?: any[]
        store_domain?: any[]
        product_domain?: any[]
        parent_path?: string
        pic_id?: number
      },
    ) => {
      return this.api.post('/in_season_analysis/in_season_product_v2', {
        ...(params || {}),
      })
    },
    getAnalysisBy: (
      params: SearchParams & {
        display_season_id?: number
        comparison_season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        md_season_id?: number
        customer_domain?: any[]
        store_domain?: any[]
        product_domain?: any[]
        parent_path?: string
        pic_id?: number
        order_pic?: number
        sales_classification?: string
        not_null_fields?: any[]
        from: 'analysis_by_product' | 'analysis_by_category'
        today_date?: string
        exclude_groupby_fields?: string[]
        calculate_total?: boolean
        virtual_store_id?: number
        aggregation_type: string
        summary_item: number
        season_date: any
        sales_attribute_domain: string
        organization_domain: any[]
      },
    ) => {
      return this.api.post('/in_season_analysis/analysis_by', {
        ...(params || {}),
        context: {
          lang: this.currentLanguage,
          tz: getLocalTimeZoneName(),
        },
      })
    },
    getCategoryCalculationAnalysis: (
      params: SearchParams & {
        season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        md_season_id?: number
        fields?: any[]
        not_null_fields?: any[]
        customer_domain?: any[]
        store_domain?: any[]
      },
    ) => {
      return this.api.post('/in_season_analysis/in_season_category_v2', {
        ...(params || {}),
      })
    },
  }

  RankingAnalysis = {
    ranking_query: (
      data: {
        season_id?: number
        organization_id?: number
        region_id?: number
        location_id?: number
        sales_channel_id?: number
        product_ids: number[]
        domain?: any[]
        fields?: string[]
        from?: string
      },
      setCancel?,
    ) => {
      const { CancelToken } = axios
      return this.api.post('/in_season_analysis/top_10_analysis', data, {
        cancelToken: new CancelToken((cancel) => {
          if (setCancel) setCancel((prev) => ({ ...prev, cancel }))
        }),
      })
    },
  }

  CategoryPurchaseQuantitySetting = {
    getDatas: (
      params?: GetCategoryPurchaseQuantitySettingParams,
    ): Promise<ReadResponse<any[]>> => {
      return this.api
        .post('/dataset/category.purchase.quantity.setting/get_datas', {
          params: {
            args: [0],
            kwargs: {
              ...params,
              context: {
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data)
    },
  }

  PDF = {
    get: (
      slug: string,
      id: number | string,
      query = '',
      withContext = false,
    ) => {
      /**
       * Remove /ja from the url if exists
       */
      let downloadUrl =
        `${BASE_API_URL}/report/pdf/ldx_core.${slug}/${id}${query}`.replace(
          /ja\/api/g,
          'api',
        )

      /**
       * Force change the protocol to https
       */
      if (
        !downloadUrl.includes('odoo-dev') &&
        !downloadUrl.includes('odoo-local')
      ) {
        downloadUrl = downloadUrl.replaceAll('http://', 'https://')
      }
      const formData = new FormData()
      formData.append('context', JSON.stringify({ lang: this.currentLanguage }))

      if (withContext) {
        return this.api.post(downloadUrl, formData)
      }

      return this.api.post(downloadUrl)
    },
    download: (
      id: number | string,
      action: string,
      query?: Record<string, any>,
    ) => {
      /**
       * Default action is order-sheet
       *
       * if action is invoice-preview override the default url
       */
      let downloadUrl = `${BASE_API_URL}/report/pdf/ldx_core.order_sheet_report`
      if (action === 'invoice-preview') {
        downloadUrl = `${BASE_API_URL}/report/pdf/ldx_core.sales_billing_report`
      } else if (action === 'report-payment-statement') {
        downloadUrl = `${BASE_API_URL}/report/pdf/ldx_core.report_payment_statement`
      }
      const formData = new FormData()
      formData.append('doc_ids', String(id))
      if (query) {
        Object.entries(query).forEach(([key, value]) => {
          formData.append(key, String(value))
        })
      }

      /**
       * Remove /ja from the url if exists
       */
      let url = downloadUrl.replace(/ja\/api/g, 'api')

      /**
       * Force change the protocol to https
       */
      if (!url.includes('odoo-dev') && !url.includes('odoo-local')) {
        url = url.replaceAll('http://', 'https://')
      }

      return this.api.post(url, formData)
    },
    writeFile: (
      data: any,
      {
        filename,
        previewOnly = false,
      }: { filename?: string; previewOnly?: boolean } = {},
    ) => {
      const byteCharacters = atob(data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const url = window.URL.createObjectURL(
        new Blob([byteArray], { type: 'application/pdf;charset=utf-8' }),
      )

      if (previewOnly) {
        window.open(url)
      } else {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${filename ?? `download_${Date.now()}`}.pdf`,
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      }
    },
  }

  ContinuedProductAnalysis = {
    getProducts: ({
      fields,
      domain,
      limit,
      offset,
      sort,
    }: {
      fields?: string[]
      domain?: any
      limit?: number | boolean
      offset?: number | boolean
      sort?: string
    } = {}) => {
      return this.api.post('/dataset/product.product/search_read', {
        params: {
          fields,
          domain,
          limit,
          offset,
          sort,
        },
      })
    },
    overall: (data: {
      product_ids: number[]
      season_id?: string | number
      start_date?: string
      end_date?: string
      out_of_stock_allowance_rate?: any
      organization_id?: string | number
    }) => {
      return this.api.post(
        '/in_season_analysis/continuous_turnover_analysis',
        data,
      )
    },
  }

  MDInventoryAnalysis = {
    computeSkeletons: (params: {
      setting_id: string | number
      limit: number
      offset: number
      sort: string | false
    }) => {
      const {
        setting_id = '',
        limit = false,
        offset = false,
        sort = false,
      } = params
      return this.api.post(
        `${BASE_API_URL}/dataset/md.inventory.sales.analysis/compute_skeleton_data`,
        {
          params: {
            args: [],
            kwargs: {
              payload: {
                setting_id,
                limit,
                offset,
                sort,
              },
              context: {
                tz: getLocalTimeZoneName(),
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
    /**
     * Compute starting inventory and save it into database.
     * This endpoint should be called necessaryly by implementing a button
     * to engage it
     */
    computeRowData: (
      payload: {
        setting_id: number
        season_id: number | string
        first_classification_id: number
        second_classification_id: number
        third_classification_id: number
        fourth_classification_id: number
        fifth_classification_id: number
        inventory_age: string
      },
      config?: AxiosRequestConfig,
    ) => {
      return this.api.post(
        `${BASE_API_URL}/dataset/md.inventory.sales.analysis/compute_row_data`,
        {
          params: {
            args: [''],
            kwargs: {
              payload,
            },
          },
        },
        config,
      )
    },
    readPeriodSkeletons: (payload: {
      setting_id: number
      limit: number
      offset: number
      start_date: string
      end_date: string
      sort: string | false
    }) => {
      return this.api.post(
        `${BASE_API_URL}/dataset/md.inventory.sales.analysis/read_range_skeleton_data`,
        {
          params: {
            args: [''],
            kwargs: {
              payload,
            },
          },
        },
      )
    },
    readPeriodRowData: (
      payload: {
        start_date: string
        end_date: string
        setting_id: number
        season_id: number | string
        first_classification_id: number
        second_classification_id: number
        third_classification_id: number
        fourth_classification_id: number
        fifth_classification_id: number
        inventory_age: string
      },
      config?: AxiosRequestConfig,
    ) => {
      return this.api.post(
        `${BASE_API_URL}/dataset/md.inventory.sales.analysis/read_range_row_data`,
        {
          params: {
            args: [''],
            kwargs: {
              payload,
            },
          },
        },
        config,
      )
    },
  }

  /**
   * Mostly used by MD-07
   */
  InventoryForecast = {
    rowData: (
      data: {
        season_id?: string | number
        start_date?: string
        end_date?: string
        out_of_stock_allowance_rate?: any
        organization_id?: string | number
      },
      config?: AxiosRequestConfig,
    ) => {
      return this.api.post(
        '/dataset/md.inventory.estimation/read_row_data',
        {
          params: {
            args: [''],
            kwargs: {
              payload: data,
            },
          },
        },
        config,
      )
    },
  }

  InterStore = {
    getProducts: ({
      fields,
      domain,
      limit,
      offset,
      sort,
    }: {
      fields?: string[]
      domain?: any
      limit?: number | boolean
      offset?: number | boolean
      sort?: string
    } = {}) => {
      return this.api.post('/dataset/product.product/search_read', {
        params: {
          fields,
          domain,
          limit,
          offset,
          sort,
        },
      })
    },
    getStores: ({
      fields,
      domain,
    }: {
      fields?: string[]
      domain?: any
    } = {}) => {
      return this.api.post('/dataset/res.partner/search_read', {
        params: {
          fields,
          domain: [
            ...domain,
            ['partner_classification', '=', 'store'], //* REQUIRED! to distinguish between store and customer
          ],
        },
      })
    },
    getInterStore: (data: { product_id: number }) => {
      return this.api.post(
        '/in_season_analysis/inter_store_movement_analysis',
        data,
      )
    },
    getInterStoreDetails: (data: { product_id: number }) => {
      return this.api.post(
        '/in_season_analysis/inter_store_movement_analysis_detail',
        data,
      )
    },
    getInterStoreShipment: (data: {
      product_id: number
      dest_store_id: number
    }) => {
      return this.api.post(
        '/in_season_analysis/inter_store_movement_analysis_shipment',
        data,
      )
    },
  }

  StockForecastAnalysis = {
    getStockForecastAnalysis: ({
      setting_id,
      product_domain,
      limit,
      offset,
    }: {
      setting_id?: number
      product_domain?: any[]
      limit?: number | boolean
      offset?: number | boolean
    } = {}) => {
      return this.api.post('/in_season/stock_forecast_analysis/data_read', {
        setting_id,
        product_domain,
        limit,
        offset,
      })
    },
    getNrow: (payload: any[], setCancel) => {
      const { CancelToken } = axios
      return this.api.post(
        '/in_season/stock_forecast_analysis/data_read_by_nrow',
        {
          data: payload,
        },
        {
          cancelToken: new CancelToken((cancel) =>
            setCancel((prev) => ({ ...prev, cancel })),
          ),
        },
      )
    },
    getRow: (payload: any[], setCancel) => {
      const { CancelToken } = axios
      return this.api.post(
        '/in_season/stock_forecast_analysis/data_read_by_row',
        payload,
        {
          cancelToken: new CancelToken((cancel) =>
            setCancel((prev) => ({ ...prev, cancel })),
          ),
        },
      )
    },
    ...this._generateCrud('stock.forecast.analysis'),
  }

  CustomerProduct = this._generateCrud('customer.product')

  Clasification = this._generateCrud('classification.master')

  SeamlessCustomer = {
    Membership: {
      ...this._generateCrud('membership'),
      Setting: {
        ...this._generateCrud('membership.setting'),
        StoreSetting: this._generateCrud('membership.setting.store.setting'),
        AdditionalPointGradeMaster: this._generateCrud(
          'membership.setting.additional.point.grade.master',
        ),
        AdditionalPointMaster: this._generateCrud(
          'membership.setting.additional.point.master',
        ),
        GradeMaster: this._generateCrud('membership.setting.grade.master'),
      },
      GradeMaster: this._generateCrud('membership.grade.master'),
      AdditionalPointMaster: this._generateCrud(
        'membership.additional.point.master',
      ),
    },
    EC_OrderReceive: {
      ...this._generateCrud('ec.order.receive'),
      writeWithContext: async (id: number | number[], data: any) => {
        const res = await this.api.post(`/dataset/ec.order.receive/write/`, {
          params: {
            args: [id, data],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                display_option: 'epm-unit',
              },
            },
          },
        })

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate)?.result
      },
      Lines: this._generateCrud('ec.order.receive.lines'),

      getStatusList: (params: SearchParams) => {
        return this.api.post('/dataset/ec.order.receive.status/search_read', {
          params: { ...params },
        })
      },

      getStickerList: (params: SearchParams) => {
        return this.api.post('/dataset/ec.instruction.sticker/search_read', {
          params: { ...params },
        })
      },
      getLines: (params: SearchParams) => {
        return this.api.post('/dataset/ec.order.receive.lines/search_read', {
          params: { ...params },
        })
      },
      createSales: async ({ id }: { id: number }) => {
        const res = await this.api.post(
          '/dataset/ec.order.receive/create_sales',
          {
            params: {
              args: [Number(id)],
              kwargs: {},
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate)?.result
      },
      createShipment: async ({ id }: { id: number }) => {
        const res = await this.api.post(
          '/dataset/ec.order.receive/create_shipment',
          {
            params: {
              args: [Number(id)],
              kwargs: {},
            },
          },
        )
        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate)?.result
      },
      downloadExcel: ({
        headers,
        convert_fields,
        domain,
        currency_fields,
        date_fields,
        datetime_fields,
        ratio_fields,
      }: {
        headers: any[]
        convert_fields: any
        currency_fields: string[]
        date_fields?: string[]
        datetime_fields?: string[]
        domain: any
        ratio_fields?: any[]
      }) => {
        return this.api.post(
          '/dataset/ec.order.receive/download_excel',
          {
            params: {
              args: [],
              kwargs: {
                headers,
                convert_fields,
                date_fields,
                datetime_fields,
                currency_fields,
                ratio_fields,
                domain,
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
      },
      fetchSlipStatus: () => {
        return this.api.post('/dataset/ec.order.receive/fetch_slip_status', {
          params: {
            args: [],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
      },
    },
    EC_OrderReceiveReturn: {
      ...this._generateCrud('ec.order.receive.return'),
      downloadExcel: ({
        headers,
        convert_fields,
        domain,
        currency_fields,
        date_fields,
        ratio_fields,
      }: {
        headers: any[]
        convert_fields: any
        currency_fields: string[]
        date_fields: string[]
        domain: any
        ratio_fields?: any[]
      }) => {
        return this.api.post(
          '/dataset/ec.order.receive.return/download_excel',
          {
            params: {
              args: [],
              kwargs: {
                headers,
                convert_fields,
                date_fields,
                currency_fields,
                ratio_fields,
                domain,

                lang: this.currentLanguage,
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
      },
    },
    EC_OrderReceiveReturnLines: {
      getByIdReturn: (id: number) => {
        return this.api.post(
          '/dataset/ec.order.receive.return.lines/search_read',
          {
            params: {
              domain: [['order_line_id', '=', id]],
              fields: [],
              limit: false,
              offset: false,
              sort: 'create_date desc',
            },
          },
        )
      },
    },
    MembershipMaster: {
      ...this._generateCrud('membership.master'),

      mergeAccount: ({ id }: { id: number }) => {
        return this.api.post(`/dataset/membership.master/merge_account/`, {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        })
      },
    },
    ECReserve: {
      ...this._generateCrud('ec.reserve'),
      inventoryCheck: (id: number) => {
        return this.api
          .post('/dataset/ec.reserve/inventory_check/', {
            params: {
              args: [[id]],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      moveToReserve: (id: number) => {
        return this.api
          .post('/dataset/ec.reserve/register/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      cancel: (ids: number[]) => {
        return this.api
          .post('/dataset/ec.reserve/cancel/', {
            params: {
              args: [ids],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      complete: (ids: number[]) => {
        return this.api
          .post('/dataset/ec.reserve/complete/', {
            params: {
              args: [ids],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      Line: {
        ...this._generateCrud('ec.reserve.line'),
      },
      Request: {
        ...this._generateCrud('ec.reserve.request'),
      },
    },
    ECProductArrival: {
      ...this._generateCrud('ec.product'),
      read: (id: number | number[]) => {
        return this.api
          .post('/dataset/ec.product/read/', {
            params: {
              args: [[id], []],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
    },
    ECProductDeployment: {
      ...this._generateCrud('ec.product.detail'),
      Image: this._generateCrud('ec.product.image'),
    },
    ECProductMerge: {
      ...this._generateCrud('ec.product.merge'),
    },
    SootingInstruction: {
      ...this._generateCrud('ec.product.request'),
      approve: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/approve/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      cancel: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/cancel/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      canceled: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/canceled/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      reject_cancel_request: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/can_not_cancel/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      cancel_request: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/cancel_request/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      complete: (id: number) => {
        return this.api
          .post('/dataset/ec.product.request/complete/', {
            params: {
              args: [id],

              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          })
          .then((res) => res.data)
      },
      create_shipment: async <
        Data = any,
        Response = ResponseCreate | ResponseError,
      >(
        data: Data,
        kwargs: Record<string, any> = {},
      ) => {
        const res = await this.api.post<any, AxiosResponse<Response>>(
          `/dataset/ec.product.request/create_shipment/`,
          {
            params: {
              args: [data],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
                ...kwargs,
              },
            },
          },
        )

        if (isError(res?.data)) {
          throw new Error(res?.data.error.data.message)
        }

        return (res?.data as unknown as ResponseCreate).result
      },
    },
  }

  CustomerBrandMaster = this._generateCrud('customer.brand.master')

  CurrencyMaster = this._generateCrud('res.currency')

  CompositionMaster = this._generateCrud('composition.master')

  FormTransaction = this._generateCrud('form.transaction')

  OperationForm = this._generateCrud('form.operation')

  LocationMaster = this._generateCrud('location.master')

  RegionMaster = this._generateCrud('region.master')

  ProductLedger = {
    ...this._generateCrud<ProductLedger>('product.ledger'),
    Quantity: this._generateCrud('product.ledger.stock.quant'),
    History: this._generateCrud('product.ledger.stock.move'),
    productInformation: async (
      product_code: string,
      type: 'color' | 'size',
      organization_id?: any,
    ) => {
      const data = await this.api
        .post(`/dataset/product.ledger/product_information`, {
          params: {
            args: [
              {
                product_code,
                type,
                organization_id,
                context: { tz: getLocalTimeZoneName() },
              },
            ],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        })
        .then((res) => res.data.result)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationCustomerQuantity: async (params: SearchParams) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_CUSTOMER_ORDER, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationOrderQuantity: async (
      params: SearchParams & { backorder_or_balance: boolean },
    ) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_ORDER, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationStockPicking: async (
      params: SearchParams & { confirmed: boolean },
    ) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_STOCK_PICKING, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationSales: async (
      params: SearchParams & { confirmed: boolean },
    ) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_SALES, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationReturn: async (
      params: SearchParams & { confirmed: boolean },
    ) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_RETURN, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
    productInformationWarehouse: async (
      params: SearchParams & { category: InHouseInventoryWarehouseQuantity },
    ) => {
      const data = await this.api
        .post(API_ROUTE.PRODUCT_LEDGER.PRODUCT_INFORMATION_WAREHOUSE, {
          params: {
            args: [params],
            kwargs: {
              context: {
                lang: this.currentLanguage,
                tz: getLocalTimeZoneName(),
              },
            },
          },
        })
        .then((res) => res.data as ReactQueryResponse<any>)

      if (isError(data)) {
        throw new Error(data.error.data.message)
      }

      return data
    },
  }

  MembershipCarte = this._generateCrud('membership.carte')
  StockQuant = {
    ...this._generateCrud('stock.quant'),
    downloadInventoryListAll: (
      params?: Record<string, any>,
      options?: { filename: string },
    ) => {
      return this.api
        .post(
          API_ROUTE.STOCK_QUANT.DOWNLOAD_INVENTORY_LIST_ALL,
          {
            params: {
              args: params ? [params] : [],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                },
              },
            },
          },
          {
            responseType: 'blob',
          },
        )
        .then((res) => {
          const urlObject = URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.ms-excel',
            }),
          )
          const link = document.createElement('a')
          link.href = urlObject
          link.setAttribute(
            'download',
            `${
              options?.filename ?? [window.document.title, Date.now()].join('_')
            }.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          return res.data
        })
    },
    readInventoryListV2: async (
      args: SearchParams & {
        display_option?: string
        with_sellable_qty?: boolean
        group?: string[]
      },
    ) => {
      return this.api
        .post(API_ROUTE.STOCK_QUANT.READ_INVENTORY_LIST_V2, {
          params: {
            args: [
              {
                ...args,
                source_id: 0,
                source_classification: 'all',
                product_code: '',
                product_name: '',
                season_name: '',
                display_option: args.display_option || 'sku-unit',
                return_type: 'wrap-separate',
                with_sellable_qty: args.with_sellable_qty || false,
              },
            ],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        })
        .then((res) => res.data.result)
    },
  }

  NewSalesSetting = {
    ...this._generateCrud('sales.setting'),
    getBestSalesSetting: (store_id: number, product_ids: number[]) => {
      return this.api
        .post('/dataset/sales.setting/get_best_sales_setting_for_products', {
          params: {
            args: [],
            kwargs: {
              store_id,
              product_ids,
            },
          },
        })
        .then((res) => res.data.result)
    },
    getBestSalesSettingByDate: (
      store_id: number,
      product_ids: number[],
      date: string,
    ) => {
      return this.api
        .post(
          '/dataset/sales.setting/get_best_sales_setting_for_products_for_date',
          {
            params: {
              args: [],
              kwargs: {
                store_id,
                product_ids,
                date,
              },
            },
          },
        )
        .then((res) => res.data.result)
    },
  }

  ProductProduct = {
    ...this._generateCrud('product.product'),
    getAchievementData: (ids: number[]) => {
      return this.api
        .post('/dataset/product.product/get_achievement_data', {
          params: {
            args: [ids],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        })
        .then((res) => res.data.result)
    },
    getStockData: (ids: number[]) => {
      return this.api
        .post('/dataset/product.product/get_current_svl_data', {
          params: {
            args: [ids],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        })
        .then((res) => res.data.result)
    },
    compositionDetail: async (product_id: number) => {
      const { data } = await this.api.post(
        '/dataset/product.product/read_composition_detail',
        {
          params: {
            args: [''],
            kwargs: {
              product_id,
            },
          },
        },
      )

      if (isError(data)) {
        throw new Error(data.error.message)
      }

      return data.result
    },
  }

  InventoryAdjustment = this._generateCrud('inventory.adjustment')

  InventoryAdjustmentLine = this._generateCrud('inventory.adjustment.line')

  CurrencyRate = this._generateCrud('res.currency.rate')

  BundlePromotion = {
    ...this._generateCrud('bundle.promotion'),
    cancel: ({
      set_product_id,
      store_ids,
    }: {
      set_product_id: number
      store_ids: number[]
    }) => {
      return this.api.post(
        '/dataset/bundle.promotion/action_cancel_set_product',
        {
          params: {
            args: [],
            kwargs: {
              payload: {
                set_product_id,
                store_ids,
              },
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
    Item: this._generateCrud('bundle.promotion.item'),
    ProductDataDetail: {
      cancel: (params: any) => {
        return this.api.post('/dataset/bundle.promotion/product_data_detail', {
          params,
        })
      },
    },
  }

  NewStoreSales = this._generateCrud('store.sales')

  AccountPaymentRegister = {
    ...this._generateCrud('account.payment'),
    cancelPayment: (ids: { id: number; name: string }[], async: boolean) => {
      return this.api.post('/dataset/account.payment/action_post_cancel', {
        params: {
          args: [
            {
              account_payment_ids: ids,
            },
          ],

          kwargs: {
            context: {
              async,
            },
          },
        },
      })
    },
  }

  // DT yamauchi add
  Sumaregi = {
    registration: async (data: any) => {
      const response = await this.api.post('/dataset/sumaregi.setting/create', {
        params: {
          args: [data],
          kwargs: {},
        },
      })

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    connection_test: async (data: any) => {
      return this.api.get('/external_link/smaregi/test/' + data)
    },
    master_data_master_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/master_data_master_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    inventory_data_master_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/inventory_data_master_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    product_master_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_product_master_update_frequency_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    product_master_execution: async () => {
      return this.api.post(
        '/dataset/sumaregi.setting/product_master_execution',
        {
          params: {
            args: [],
            kwargs: {},
          },
        },
      )
    },
    store_master_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_store_master_update_frequency_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    store_master_execution: async (data: any) => {
      return this.api.post('/dataset/sumaregi.setting/store_master_execution', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    center_warehouse_registration_execution: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_center_warehouse_registration',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    transaction_information_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_transaction_information_update_frequency_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    transaction_information_execution: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/transaction_information_execution',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    transaction_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_shipment_update_frequency_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    transaction_execution: async (data: any) => {
      return this.api.post('/dataset/sumaregi.setting/shipment_execution', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    inventory_information_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/set_inventory_information_update_frequency_setting',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    inventory_information_execution: async (data: any) => {
      return this.api.post(
        '/dataset/sumaregi.setting/inventory_information_execution',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
  }

  ProductTemporaryStorage = {
    save: async (data: any, flg: string) => {
      const response = await this.api.post(
        '/dataset/product.temporary.storage/save',
        {
          params: {
            args: [data, flg],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    saveUpload: (id, file) => {
      const formData = new FormData()
      formData.append('sequence_id', id)
      formData.append('file', file)
      return this.api.post('/product_temporary_storage/saveUpload', formData)
    },
  }

  StoreTemporaryStorage = {
    save: async (data: any, flg: string) => {
      const response = await this.api.post(
        '/dataset/store.temporary.storage/save',
        {
          params: {
            args: [data, flg],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
  }

  ArrivalTemporaryStorage = {
    save: async (data: any, flg: string) => {
      const response = await this.api.post(
        '/dataset/arrival.temporary.storage/save',
        {
          params: {
            args: [data, flg],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
  }

  ShipmentTemporaryStorage = {
    save: async (data: any, flg: string) => {
      const response = await this.api.post(
        '/dataset/shipment.temporary.storage/save',
        {
          params: {
            args: [data, flg],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
  }

  StockTemporaryStorage = {
    save: async (data: any, flg: string) => {
      const response = await this.api.post(
        '/dataset/stock.temporary.storage/save',
        {
          params: {
            args: [data, flg],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
  }

  LogisticsLinkage = {
    get_sequences: (model: string, type: string, update_by?: string) => {
      return this.api.post(`/dataset/logistic.linkage.sequence/get_sequence`, {
        params: {
          args: [
            {
              reference_model: model,
              type,
              update_by,
            },
          ],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    last_update_date: (classification: string) => {
      return this.api.post(
        `/dataset/logistic.linkage.registration/search_read`,
        {
          params: {
            sort: 'id desc',
            context: {
              lang: 'en_US',
            },
            domain: [['reference_model', '=', classification]],
            fields: ['create_date'],
          },
          kwargs: {},
        },
      )
    },
    export: (type: 'excel' | 'csv', data: any) => {
      return this.api.post(
        `/logistic-linkage/export/${type}`,
        {
          params: {
            args: [],
            kwargs: {
              ...data,
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
    shipmentDeliveryNotePdf: ({
      domain,
      sort,
    }: {
      domain: any[]
      sort?: string
    }) => {
      return this.api.post(
        `/delivery_slip/pdf/report`,
        {
          params: {
            domain,
            sort,
            context: { lang: this.currentLanguage },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },

    import: ({
      model,
      file,
      sequence_id,
      lang,
      extras,
      async: isAsync,
      highlight_field,
      file_type,
      timezone,
    }: {
      model: string
      file: any
      sequence_id?: number
      lang?: string
      extras?: { [key: string]: any }
      async?: boolean
      highlight_field?: string[]
      file_type?: string
      timezone?: number
    }) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reference_model', model)
      formData.append('highlight_field', JSON.stringify(highlight_field))
      formData.append('file_type', file_type)
      if (sequence_id) {
        formData.append('sequence_id', sequence_id.toString())
      }
      if (extras) {
        Object.entries(extras).forEach((k) => {
          formData.append(k[0], k[1])
        })
      }
      if (lang) {
        formData.append('lang', lang)
      }
      if (typeof isAsync === 'boolean') {
        formData.append('async', isAsync as any)
      }
      if (timezone) {
        formData.append('timezone', timezone as any)
      }
      return this.api.post(`/logistic-linkage/import`, formData, {
        // timeout: 20 * 60 * 1000, // 20 menit
        timeout: 0, // No timeout for now until wbs logistic linkage improvement done
      })
    },
    create: ({
      name,
      reference_model,
      sequence_fields,
      type,
      update_by,
      product_separator,
    }: {
      name: string
      reference_model: string
      sequence_fields: { [key: string]: number }
      type?: string
      update_by?: string
      product_separator?: string
    }) => {
      return this.api.post(`/dataset/logistic.linkage.sequence/create`, {
        params: {
          args: [
            {
              name,
              reference_model,
              sequence_fields,
              type,
              update_by,
              product_separator,
            },
          ],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    write_sequence: ({
      id,
      name,
      reference_model,
      sequence_fields,
      type,
      editable_field,
      product_separator,
      additional_fields,
      exclude_header,
    }: {
      id: number
      name?: string
      reference_model: string
      sequence_fields?: { [key: string]: number }
      type?: string
      editable_field?: any
      product_separator?: string
      additional_fields?: any
      exclude_header?: boolean
    }) => {
      return this.api.post(
        `/dataset/logistic.linkage.sequence/write_sequence`,
        {
          params: {
            args: [
              id,
              {
                name,
                reference_model,
                sequence_fields,
                type,
                editable_field,
                product_separator,
                additional_fields,
                exclude_header,
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    delete_sequence: ({ id }: { id: number }) => {
      return this.api.post(`/dataset/logistic.linkage.sequence/unlink`, {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
  }
  PatternPreset = this._generateCrud('pattern.preset')
  PatternMaster = this._generateCrud('pattern.master')
  ExhibitionProductGroup = {
    ...this._generateCrud('exhibition.product.group'),
    get_distinct_product_template: async (group_id, params: SearchParams) => {
      const res = await this.api.post(
        API_ROUTE.EXHIBITION_PRODUCT_GROUP.GET_DISTINCT_PRODUCT_TEMPLATE,
        {
          params: {
            args: [{ ...params, group_id }],
            kwargs: {},
          },
        },
      )
      return res
    },
    Line: this._generateCrud('exhibition.product.group.line'),
  }
  ExhibitionMonitoring = {
    Activity: this._generateCrud('exhibition.customer.activity'),
  }
  // DT yamauchi add end

  Poscm = {
    registerStockTaking: async (kwargs: Dictionary<any>) => {
      return this.api.post(
        '/dataset/poscm/stock.taking.process.batch/register',
        {
          params: kwargs,
        },
      )
    },
    setPoscmConfig: async (kwargs: Dictionary<any>) => {
      return this.api.post('/dataset/res.config.settings/set_poscm_config', {
        params: {
          args: [],
          kwargs: kwargs,
        },
      })
    },
  }

  // NextEngine
  NextEngine = {
    registration: async (data: any) => {
      const response = await this.api.post(
        '/dataset/res.config.settings/set_next_engine_config',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )

      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
    },
    connection_test: async (data: any) => {
      return this.api.post('/dataset/external.link.request/test_connection/', {
        params: {
          args: [data],
          kwargs: {},
        },
      })
    },
    product_master_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_product_sync_frequency',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    next_engine_product_sync_write_date_gte: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_product_sync_write_date_gte',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    qty_done_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_qty_done_sync_frequency',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    next_engine_syohin_code: async (data: string) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_syohin_code',
        {
          params: {
            args: [data ?? ''],
            kwargs: {},
          },
        },
      )
    },
    central_vw_registration_execution: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_central_vw/',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    transaction_information_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_transaction_sync_frequency',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    next_engine_charge_service_id: async (data: number) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_charge_service',
        {
          params: {
            args: [data ?? ''],
            kwargs: {},
          },
        },
      )
    },
    next_engine_delivery_fee_service_id: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_delivery_fee_service',
        {
          params: {
            args: [data ?? ''],
            kwargs: {},
          },
        },
      )
    },
    set_next_engine_other_amount_settings: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_other_amount_settings',
        {
          params: {
            args: [data ?? {}],
            kwargs: {},
          },
        },
      )
    },
    inventory_diff_update_frequency_setting: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_inventory_diff_get_frequency',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    export_product_to_ne: async () => {
      return this.api.post(
        '/dataset/external.link.cron/export_products_to_ne',
        {
          params: {
            args: [[]],
            kwargs: {},
          },
        },
      )
    },
    sync_orders_with_ne: async () => {
      return this.api.post('/dataset/external.link.cron/sync_orders_with_ne', {
        params: {
          args: [[]],
          kwargs: {},
        },
      })
    },
    get_stock_diff: async () => {
      return this.api.post('/dataset/external.link.cron/get_stock_diff', {
        params: {
          args: [[]],
          kwargs: {},
        },
      })
    },
    shipment_company_ids_registration_execution: async (data: any) => {
      return this.api.post(
        '/dataset/mapping.shipment.company/set_mappings_from_delivery_in_ne/',
        {
          params: {
            args: [],
            kwargs: { data },
          },
        },
      )
    },
    billing_method_ids_registration_execution: async (
      shop_id_in_ne: number,
      data: any,
    ) => {
      return this.api.post(
        '/dataset/mapping.billing.method/set_mappings_from_payment_method_in_ne/',
        {
          params: {
            args: [],
            kwargs: { shop_id_in_ne: shop_id_in_ne, data: data },
          },
        },
      )
    },
    set_next_engine_point_settings: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_point_settings/',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
    set_next_engine_shipping_remarks: async (data: any) => {
      return this.api.post(
        '/dataset/res.config.settings/set_next_engine_shipping_remarks/',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
    },
  }
  // NextEngine end

  // TeamStore
  MappingPartnerTeamstore = {
    ...this._generateCrud('mapping.res.partner.and.teamstore'),
    create: (data: any) => {
      return this.api.post(
        '/dataset/mapping.res.partner.and.teamstore/create',
        {
          params: {
            args: [data],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
    update: (id: number, data: any) => {
      return this.api.post('dataset/mapping.res.partner.and.teamstore/write/', {
        params: {
          args: [id, data],

          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    delete: (ids: number, _lang?: string) => {
      return this.api.post(
        '/dataset/mapping.res.partner.and.teamstore/unlink',
        {
          params: {
            args: [ids],

            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
  }
  // TeamStore end

  InSeasonAnalysis = {
    summaryTableWithPictures: async (
      params: SummaryTableWithPicturesSchema,
    ) => {
      return this.api
        .post<{
          result: { length: number; records: SummaryTableWithPicturesData[] }
        }>(
          '/in_season_analysis/summary_table_with_pictures/',
          {
            ...params,
            context: {
              lang: this.currentLanguage,
              tz: getLocalTimeZoneName(),
            },
          },
          {
            headers: {
              'content-type': 'application/json',
            },
          },
        )
        .then((res) => {
          if (isError(res.data)) {
            throw new Error(
              res.data.error.data.message || res.data.error.message,
            )
          }

          return res.data
        })
    },
    AggregationOrganization: (data: any) => {
      return this.api.post('/in_season_analysis/aggregation_by_organization', {
        ...data,
        context: {
          lang: this.currentLanguage,
          tz: getLocalTimeZoneName(),
        },
      })
    },

    AggregationProduct: (data: any) => {
      return this.api.post('/in_season_analysis/aggregation_by_product', {
        ...data,
        context: {
          lang: this.currentLanguage,
          tz: getLocalTimeZoneName(),
        },
      })
    },

    summaryTableWithPicturesReport: async (
      params: SummaryTableWithPicturesSchema,
    ) => {
      const url = '/in_season_analysis/summary_table_with_pictures/export_excel'
      const payload = {
        ...params,
        context: { lang: this.currentLanguage, tz: getLocalTimeZoneName() },
      }

      return this.api
        .post(url, payload, { responseType: 'blob' })
        .catch(() => this.api.post(url, payload))
    },

    wholesaleShipmentRateReport: async (params: { view_hash?: string }) => {
      const url = '/in_season_analysis/wholesale_v2/export_excel'
      const payload = {
        ...params,
        context: { lang: this.currentLanguage, tz: getLocalTimeZoneName() },
      }

      return this.api
        .post(url, payload, { responseType: 'blob' })
        .catch(() => this.api.post(url, payload))
    },

    rankingAnalysisReport: async (params: {
      view_hash?: string
      final_query?: string
    }) => {
      const url = '/in_season_analysis/top_10_analysis/export_excel'
      const payload = {
        ...params,
        context: { lang: this.currentLanguage, tz: getLocalTimeZoneName() },
      }

      return this.api
        .post(url, payload, { responseType: 'blob' })
        .catch(() => this.api.post(url, payload))
    },
  }

  Notification = {
    getAutoNotification: async (data: any) => {
      return await this.api.post(
        '/dataset/alert.notification/get_notification',
        {
          params: {
            args: [],
            kwargs: data,
          },
        },
      )
    },
    getForUser: async (domain: any[][], id: number) => {
      return await this.api.post(
        '/dataset/alert.notification/get_notification_for_user',
        {
          params: {
            args: [...domain],
            kwargs: {
              user_id: id,
            },
          },
        },
      )
    },
    markAllRead: async () => {
      return await this.api.post('/dataset/alert.notification/mark_all_read', {
        params: {
          args: [],
          kwargs: {},
        },
      })
    },
    getAutoThread: async (data: any) => {
      return await this.api.post('/dataset/alert.notification/get_thread', {
        params: {
          args: [],
          kwargs: data,
        },
      })
    },
    sendThread: async (data: any) => {
      return await this.api.post('/dataset/alert.notification/send_thread', {
        params: {
          args: [],
          kwargs: data,
        },
      })
    },
    sendNotification: async (data: any) => {
      return await this.api.post(
        '/dataset/alert.notification/send_notification',
        {
          params: {
            args: [],
            kwargs: data,
          },
        },
      )
    },
  }

  Coupon = {
    ...this._generateCrud('ec.coupon'),
    Coupon: this._generateCrud('coupon.coupon'),
    publish: (id: number) => {
      return this.api.post(`/dataset/ec.coupon/publish`, {
        params: {
          args: [id, {}],
          kwargs: {},
        },
      })
    },
    suspend: (id: number) => {
      return this.api.post(`/dataset/ec.coupon/suspend`, {
        params: {
          args: [id, {}],
          kwargs: {},
        },
      })
    },
    end: (id: number) => {
      return this.api.post(`/dataset/ec.coupon/end`, {
        params: {
          args: [id, {}],
          kwargs: {},
        },
      })
    },
  }

  CouponUsage = {
    ...this._generateCrud('ec.coupon.history'),
    upload: (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('lang', this.currentLanguage)
      return this.api.post(`/ec/coupon/history/upload`, formData)
    },
    download_excel: (id: number, headers: any[]) => {
      return this.api.post(
        `/dataset/ec.coupon/download_excel`,
        {
          params: {
            kwargs: {
              headers,
              method: 'address_list_download',
              method_args: [
                {
                  domain: [['id', '=', id]],
                },
              ],
              lang: this.currentLanguage,
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
  }

  CartManagement = {
    ...this._generateCrud('cart.setting'),
    download_excel: (params: any, headers: any[]) => {
      return this.api.post(
        `/dataset/cart.setting/download_excel`,
        {
          params: {
            args: [],
            kwargs: {
              method: 'get_data_for_export',
              method_args: [],
              method_kwargs: params,
              headers,
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
  }

  FirstCustomerGroupMaster = this._generateCrud('first.customer.group.master')
  SecondCustomerGroupMaster = this._generateCrud('second.customer.group.master')
  ThirdCustomerGroupMaster = this._generateCrud('third.customer.group.master')
  ExhibitionCustomerOrder = {
    ...this._generateCrud('exhibition.customer.order'),
    registerCustomerOrder: (data: any) => {
      return this.api.post(API_ROUTE.EXHIBITION_ORDER.REGISTER_CUSTOMER_ORDER, {
        params: {
          args: data,
          kwargs: {
            context: {
              language: this.currentLanguage,
            },
          },
        },
      })
    },
    Password: {
      set: (data) =>
        this.api.post('exhibition_customer_order/set_password', data),
      check: (data) => this.api.post('exhibition_customer_order/check', data),
      status: () =>
        this.api.post(
          'exhibition_customer_order/exhibition_password_status',
          {},
        ),
    },
  }
  ExhibitionCustomerOrderLine = this._generateCrud(
    'exhibition.customer.order.line',
  )
  ShipmentCompany = this._generateCrud('shipment.company')
  ManufacturingOrder = {
    ...this._generateCrud('manufacturing.order'),
    startProduction: async (id: number) => {
      return this.api.post(
        API_ROUTE.MANUFACTURING_ORDER.ACTION_START_MANUFACTURING_ORDER,
        {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
    confirmProduction: async (id: number) => {
      return this.api.post(
        API_ROUTE.MANUFACTURING_ORDER.ACTION_CONFIRM_MANUFACTURING_ORDER,
        {
          params: {
            args: [id],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
    confirmService: async (id: number) => {
      return this.api.post(API_ROUTE.MANUFACTURING_ORDER.CREATE_SERVICE_AP, {
        params: {
          args: [id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    cancelOrder: async (id: number) => {
      return this.api.post(API_ROUTE.MANUFACTURING_ORDER.ACTION_CANCEL, {
        params: {
          args: [id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }
  ManufacturingOrderLine = this._generateCrud('manufacturing.order.line')
  ManufacturingOrderServicer = this._generateCrud('manufacturing.order.service')
  ManufacturingOrderLineMaterial = this._generateCrud(
    'manufacturing.order.line.material',
  )
  MRPBOM = this._generateCrud('mrp.bom')
  MRPBOMAssembly = this._generateCrud('mrp.bom.assembly')
  MRPBOMLine = this._generateCrud('mrp.bom.line')
  MDProduct = {
    dataReadProduct: async (params: any) => {
      return this.api.post(API_ROUTE.MD_PRODUCT.DATA_READ_PRODUCT, {
        params: {
          args: [''],
          kwargs: {
            payload: {
              ...params,
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      })
    },
    dataRead: async (setting_id: number, product_ids: number[]) => {
      return this.api.post(API_ROUTE.MD_PRODUCT.DATA_READ, {
        params: {
          args: [{ setting_id, product_ids }],
          kwargs: {},
        },
      })
    },
  }

  SafetyStockSetting = {
    ...this._generateCrud('safety.inventory.setting'),
    get_safety_inventory_setting_by_store: async (
      params: SearchParams & {
        store_id: number
      },
    ) => {
      return this.api.post(
        '/dataset/safety.inventory.setting/get_safety_inventory_setting_by_store',
        {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
  }

  WarehouseSafetyStockSetting = {
    ...this._generateCrud('warehouse.safety.inventory.setting'),
    get_safety_inventory_setting_by_warehouse: async (
      params: SearchParams & {
        warehouse_id: number
        virtual_warehouse_id: number
      },
    ) => {
      return this.api.post(
        '/dataset/warehouse.safety.inventory.setting/get_safety_inventory_setting_by_warehouse',
        {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        },
      )
    },
  }

  MaterialReplenishmentRequest = {
    ...this._generateCrud('material.replenishment.request'),
    generateMaterialRequest: ({
      product_line_ids,
    }: {
      product_line_ids: any[]
    }) => {
      return this.api.post(
        '/dataset/material.replenishment.request/generate_material_request',
        {
          params: {
            args: [],
            kwargs: {
              payload: {
                product_line_ids,
              },
            },
          },
        },
      )
    },
    updateBatchLine: ({
      request_ids,
    }: {
      request_ids: { request_id: number; replenishment_actual_qty: number }[]
    }) => {
      return this.api.post(
        '/dataset/material.replenishment.request/action_update_actual_qty',
        {
          params: {
            args: [],
            kwargs: {
              payload: { request_ids },
            },
          },
        },
      )
    },
    actionReplenishmentComplete: ({ ids }: { ids: number[] }) => {
      return this.api.post(
        '/dataset/material.replenishment.request/action_replenishment_complete',
        {
          params: {
            args: [ids],
            kwargs: {},
          },
        },
      )
    },
    createArrival: ({
      ids,
      source_id,
      source_type,
      location_id,
    }: {
      ids: number[]
      source_type: string
      source_id: number
      location_id: number
    }) => {
      return this.api.post(
        '/dataset/material.replenishment.request/create_arrival',
        {
          params: {
            args: [],
            kwargs: {
              payload: {
                request_ids: ids,
                source_id,
                source_type,
                location_id,
              },
            },
          },
        },
      )
    },
    downloadPDF: ({
      filename,
      sheet_no,
    }: {
      filename?: string
      sheet_no: string
    }) => {
      return this.api
        .get(`/material_replenishment_request/pdf/${sheet_no}`, {
          responseType: 'blob',
        })
        .then((resp) => {
          const url = window.URL.createObjectURL(
            new Blob([resp.data], { type: 'application/pdf;charset=utf-8' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${filename ?? `download_${Date.now()}`}.pdf`,
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
    },
  }
  PaymentStatement = {
    ...this._generateCrud('payment.statement'),
    download: (id: number | string, filename) => {
      const downloadUrl = `${BASE_API_URL}/payment_statement/pdf`

      // /**
      //  * Remove /ja from the url if exists
      //  */
      let url = downloadUrl.replace(/ja\/api/g, 'api')

      // /**
      //  * Force change the protocol to https
      //  */
      if (!url.includes('odoo-dev') && !url.includes('odoo-local')) {
        url = url.replaceAll('http://', 'https://')
      }

      const formData = new FormData()
      formData.append('doc_ids', String(id))

      return this.api
        .post(downloadUrl, formData, {
          responseType: 'blob',
        })
        .then((resp) => {
          const url = window.URL.createObjectURL(
            new Blob([resp.data], { type: 'application/pdf;charset=utf-8' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${filename ?? `download_${Date.now()}`}.pdf`,
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
    },
    getAccountMoveLinesByGroup: (id: number) => {
      // id ==> payment statement id
      return this.api.post(
        '/dataset/payment.statement/get_account_move_line_ids',
        {
          params: {
            args: [id],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },
    getPaymentSummary: ({ id }: { id: number }) => {
      return this.api.post('/dataset/payment.statement/get_payment_summary', {
        params: {
          args: [id],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    getGroupedTax: ({ id }: { id: number }) => {
      return this.api.post('/dataset/payment.statement/get_grouped_tax', {
        params: {
          args: [id],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    BatchSession: {
      ...this._generateCrud('payment.statement.batch.session'),
      requestDownload: ({
        id,
        direct_download,
      }: {
        id: number | number[]
        direct_download?: boolean
      }) => {
        return this.api.post(
          '/dataset/payment.statement.batch.session/download_pdf',
          {
            params: {
              args: [
                id,
                {
                  direct_download,
                },
              ],
              kwargs: {
                context: { lang: this.currentLanguage },
              },
            },
          },
        )
      },
      downloadPDF: ({ id, filename }: { id: number; filename?: string }) => {
        return this.api
          .post(
            `/dataset/payment.statement.batch.session/action_download_pdf`,
            {
              responseType: 'blob',
              params: {
                args: [
                  id, // payment.statement.batch.session ID
                ],
                kwargs: {},
              },
            },
          )
          .then((resp) => {
            const url = window.URL.createObjectURL(
              new Blob([resp.data], { type: 'application/pdf;charset=utf-8' }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `${filename ?? `download_${Date.now()}`}.pdf`,
            )
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
          })
      },
      retryJob: (id: number | number[]) => {
        return this.api.post(
          '/dataset/payment.statement.batch.session/action_retry_job',
          {
            params: {
              args: [id],
              kwargs: {},
            },
          },
        )
      },
      getAllPaymentStatementIds: (id: number | number[]) => {
        return this.api.post(
          '/dataset/payment.statement.batch.session/get_all_payment_statement_ids',
          {
            params: {
              args: [id],
              kwargs: {},
            },
          },
        )
      },
    },
  }

  RFMSettingLine = {
    ...this._generateCrud('rfm.setting.line'),
  }
  RFMSetting = {
    ...this._generateCrud('rfm.setting'),
    rfm_analysis: async (params) => {
      const res = await this.api.post(
        '/dataset/rfm.setting/calculate_rfm_analysis',
        {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {},
          },
        },
      )

      if (res?.data?.error) {
        throw new Error(res?.data.error.data.message)
      }

      return res
    },
    statictics: async (params) => {
      const res = await this.api.post(
        '/dataset/rfm.setting/calculate_rfm_statistics',
        {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {},
          },
        },
      )

      if (res?.data?.error) {
        throw new Error(res?.data.error.data.message)
      }

      return res
    },

    reflectedCustomerData: async (params) => {
      const res = await this.api.post(
        '/dataset/rfm.setting/rfm_reflect_customer_data',
        {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {},
          },
        },
      )

      if (res?.data?.error) {
        throw new Error(res?.data.error.data.message)
      }

      return res
    },

    requestDownload: async (params) => {
      const res = await this.api.post('/dataset/rfm.setting/download', {
        params: {
          args: [
            {
              ...params,
            },
          ],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })

      if (res?.data?.error) {
        throw new Error(res?.data.error.data.message)
      }

      return res
    },
  }
  RFMSegmentName = {
    ...this._generateCrud('segment.setting.name'),
  }

  InstructionSheet = {
    ...this._generateCrud('sheet.instruction'),
    confirm: (id: number) => {
      return this.api.post('/dataset/sheet.instruction/action_confirm', {
        params: {
          args: [id],

          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },
    duplicates: (ids: number[]) => {
      return this.api.post('/dataset/sheet.instruction/duplicates', {
        params: {
          args: [],
          kwargs: {
            records: ids,
            lang: this.currentLanguage,
          },
        },
      })
    },
    getManufacturingOrderRequiredMaterial: ({
      id,
      params = {},
    }: {
      id: number
      params?: Record<string, any>
    }) => {
      return this.api
        .post('/dataset/sheet.instruction/get_preview_required_material', {
          params: {
            args: [[id], params],
            kwargs: {
              // lang: this.currentLanguage,
            },
          },
        })
        .then((resp) => resp.data)
    },
  }

  AccessoryPosition = {
    ...this._generateCrud('master.accessory.position'),
  }

  AuxiliaryMaterial = {
    ...this._generateCrud('auxiliary.material.master'),
  }

  ProductSupplierAttribute = {
    ...this._generateCrud('product.supplier.attribute'),
  }

  InstructionSheetFile = {
    ...this._generateCrud('sheet.instruction.specification.file'),
    upload: async (params) => {
      const formData = new FormData()
      Object.entries(params).forEach((item) => {
        const [key, value] = item as any
        formData.append(key, value)
      })
      const res = await this.api.post(
        '/sheet_instruction_specification/upload',
        formData,
      )

      if (res?.data?.error) {
        throw new Error(res?.data.error.data.message)
      }

      return res
    },
  }

  SheetInstructionSize = {
    ...this._generateCrud('sheet.instruction.size'),
  }
  InstructionSheetSewing = {
    ...this._generateCrud('sheet.instruction.sewing'),
  }

  InstructionSheetFabric = {
    ...this._generateCrud('sheet.instruction.fabric'),
  }

  SheetInstructionAccessory = {
    ...this._generateCrud('sheet.instruction.accessory'),
  }

  JANCodeMaster = {
    ...this._generateCrud('jan.code.master'),
  }

  DatabaseAndLogs = {
    downloadDatabase: () => {
      return this.api.post(
        '/dataset/database/download',
        {},
        { responseType: 'blob' },
      )
    },
    downloadLogs: (domain: any[][]) => {
      return this.api
        .post('/dataset/logs/download', { domain }, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${`logs_${Date.now()}`}.log`)
          document.body.appendChild(link)
          link.click()
        })
    },
  }
  ErrorProduct = {
    search: async (params: SearchParams) => {
      const response = await this.api.post(
        '/dataset/product.product.in.ne/search_read',
        {
          params: {
            ...params,
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      )

      return response
    },
    unlink: (id: number | number[]) => {
      return this.api.post('/dataset/product.product.in.ne/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }
  ErrorOrders = {
    search: async (params: SearchParams) => {
      const response = await this.api.post(
        '/dataset/external.link.ec.order.receive/search_read',
        {
          params: {
            ...params,
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      )

      return response
    },
    unlink: (id: number | number[]) => {
      return this.api.post('/dataset/external.link.ec.order.receive/unlink', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    Lines: {
      search: async (params: SearchParams) => {
        const response = await this.api.post(
          '/dataset/external.link.ec.order.receive.lines/search_read',
          {
            params: {
              ...params,
              context: {
                lang: this.currentLanguage,
              },
            },
          },
        )

        return response
      },
      unlink: (id: number | number[]) => {
        return this.api.post(
          '/dataset/external.link.ec.order.receive.lines/unlink',
          {
            params: {
              args: [id],

              kwargs: {},
            },
          },
        )
      },
    },
  }

  ExcludeBillingToMaster = {
    ...this._generateCrud('exclude.billing.to.master'),
  }

  QueueJobs = {
    ...this._generateCrud('queue.job'),
    requeue: (id: number | number[]) => {
      return this.api.post('/dataset/queue.job/requeue', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
    cancel: (id: number | number[]) => {
      return this.api.post('/dataset/queue.job/button_cancelled', {
        params: {
          args: [id],

          kwargs: {},
        },
      })
    },
  }

  ExtPonitLog = {
    ...this._generateCrud('ext.point.log'),
  }

  ECReserveOrder = {
    ...this._generateCrud('ec.reserve.order'),

    actionHandler: (
      id: number,
      method: 'approve' | 'disapprove' | 'complete' | 'cancel' | 'extend',
    ) => {
      return this.api
        .post(`/dataset/ec.reserve.order/${method}`, {
          params: {
            args: [id],
            kwargs: {},
          },
        })
        .then((res) => res.data)
    },
  }

  InventoryAllocation = {
    ...this._generateCrud('inventory.allocation'),

    createAllShippingInstructions: (e) => {
      return this.api.post(
        '/dataset/inventory.allocation/create_all_shipping_instructions',
        {
          params: {
            args: [e],
            kwargs: {},
          },
        },
      )
    },

    actionReflectedCustomerOrder: ({
      data,
    }: {
      data: { allocation_ids: number[] }
    }) => {
      return this.api.post(
        '/dataset/inventory.allocation/reflected_customer_order',
        {
          params: {
            args: [data],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        },
      )
    },

    actionReflectedMDPlan: ({
      data,
    }: {
      data: { allocation_ids: number[]; md_plan_id: number }
    }) => {
      return this.api.post('/dataset/inventory.allocation/reflected_md_plan', {
        params: {
          args: [data],
          kwargs: {
            context: { lang: this.currentLanguage },
          },
        },
      })
    },

    Setting: {
      ...this._generateCrud('inventory.allocation.setting'),
    },
  }
  Vips = {
    master_data_master_setting: async (data: any, flag: number) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_each_frequency_time',
        {
          params: {
            args: [data, flag],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },
    get_initialValues_data: async () => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_get_initialValues_data',
        {
          params: {
            args: [],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },

    input_data_register_update_process: async (data: string, flag: number) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_front_connect_data',
        {
          params: {
            args: [data, flag],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },

    send_timestamp_info: async (_timestamp) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_get_initialValues_data',
        {
          params: {
            args: [],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },
    vips_manual_execution: async (data: any) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_manual_execution',
        {
          params: {
            args: [data],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },
    vips_link_warehouse_process: async (data: any, flag: number) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_link_warehouse_process',
        {
          params: {
            args: [data, flag],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },
    vips_link_warehouse_totalling: async () => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/vips_link_warehouse_totalling',
        {
          params: {
            args: [],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },

    write_or_create_record: async (
      master_classification_status: string,
      classification_status: string,
      first_interval: string,
      second_interval: string,
      time: string,
    ) => {
      const response = await this.api.post(
        '/dataset/ext.wms.link.func/write_or_create_record',
        {
          params: {
            args: [
              master_classification_status,
              classification_status,
              first_interval,
              second_interval,
              time,
            ],
            kwargs: {},
          },
        },
      )
      if (_.has(response.data, 'error')) {
        throw Error('Error!')
      }
      return response
    },
    Warehouse: this._generateCrud('ext.wms.link.warehouse.table'),
    VirtualWarehouse: this._generateCrud('ext.wms.link.vc.warehouse.table'),
  }

  StockTaking = {
    ...this._generateCrud('stock.taking'),
    viewReadSummary: (params?: SearchParams) => {
      return this._search('/dataset/dataset/stock.taking/view_read_summary', {
        ...params,
      })
    },

    viewActionSnapshot: ({
      payload,
    }: {
      payload: { warehouse_id: number; stock_taking_group_id: number }
    }) => {
      return this.api.post(`/dataset/stock.taking/view_action_snapshot`, {
        params: {
          args: [payload],
          kwargs: {},
        },
      })
    },

    readAllocationProduct: ({
      payload,
    }: {
      payload: { product_id: number; stock_taking_id: number }
    }) => {
      return this.api.post(
        `/dataset/stock.taking/action_read_allocation_product`,
        {
          params: {
            args: [
              payload.stock_taking_id,
              {
                product_id: payload.product_id,
              },
            ],
            kwargs: {},
          },
        },
      )
    },

    batchActualShelfAllocation: ({ id }) => {
      return this.api.post(
        `/dataset/stock.taking/view_action_shelf_allocation`,
        {
          params: {
            args: [id],
            kwargs: {},
          },
        },
      )
    },

    doAllocation: ({
      payload,
    }: {
      payload: {
        taking_type: string
        actual_shelf_qty: number
        lines: any
      }
    }) => {
      return this.api.post(`dataset/stock.taking/view_do_allocation`, {
        params: {
          args: [payload],
          kwargs: {},
        },
      })
    },

    BatchRegistration: {
      create_sequence: ({
        name,
        stock_taking_type,
        reference_model,
        sequence_fields,
        type,
        checking,
        ignored_fields,
      }: {
        name: string
        stock_taking_type: string
        reference_model: string
        sequence_fields: { [key: string]: number }
        type?: string
        checking?: boolean
        ignored_fields: [string] | string[]
      }) => {
        return this.api.post(`/dataset/batch.sequence/create_sequence`, {
          params: {
            args: [
              {
                name,
                stock_taking_type,
                reference_model,
                sequence_fields,
                type,
                ignored_fields,
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage, checking },
            },
          },
        })
      },

      read: ({ id }: { id: number }) => {
        return this.api.post(`/dataset/batch.sequence/read`, {
          params: {
            args: [id],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
      },

      write_sequence: ({
        id,
        name,
        stock_taking_type,
        reference_model,
        sequence_fields,
        type,
        editable_field,
        checking,
        ignored_fields,
      }: {
        id: number
        name?: string
        stock_taking_type?: string
        reference_model?: string
        sequence_fields?: { [key: string]: number }
        type?: string
        editable_field?: any
        checking?: boolean
        ignored_fields: [string] | string[]
      }) => {
        return this.api.post(`/dataset/batch.sequence/write_sequence`, {
          params: {
            args: [
              id,
              {
                name,
                stock_taking_type,
                reference_model,
                sequence_fields,
                type,
                editable_field,
                ignored_fields,
              },
            ],

            kwargs: {
              context: { lang: this.currentLanguage, checking },
            },
          },
        })
      },
    },

    actionDone: ({ stock_taking_id }: { stock_taking_id: number }) => {
      return this.api.post(`dataset/stock.taking/action_mark_done`, {
        params: {
          args: [stock_taking_id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    resetDraft: ({ stock_taking_id }: { stock_taking_id: number }) => {
      return this.api.post(`dataset/stock.taking/button_draft`, {
        params: {
          args: [stock_taking_id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    actionConfirm: ({ stock_taking_id }: { stock_taking_id: number }) => {
      return this.api.post(`dataset/stock.taking/action_mark_confirm`, {
        params: {
          args: [stock_taking_id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    cancelConfirmation: ({ stock_taking_id }: { stock_taking_id: number }) => {
      return this.api.post(`dataset/stock.taking/action_mark_cancel`, {
        params: {
          args: [stock_taking_id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },

    deleteProduct: ({
      stock_taking_id,
      product_id,
    }: {
      stock_taking_id: number
      product_id: number
    }) => {
      return this.api.post(`dataset/stock.taking/unlink_product`, {
        params: {
          args: [stock_taking_id, [product_id]],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
  }

  StockTakingGroup = {
    ...this._generateCrud('stock.taking.group'),
  }

  MultiProcess = {
    ...this._generateCrud('multi.process'),
    Line: {
      ...this._generateCrud('multi.process.line'),
    },
  }

  PriceChange = {
    ...this._generateCrud('markdown.master'),
    getProductList: async (params: SearchParams) => {
      const res = await this.api
        .post('/dataset/product.product/get_product_list_price_change', {
          params: {
            args: [
              [],
              {
                ...params,
              },
            ],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
      return res?.result
    },
    actionConfirm: (id: number) => {
      return this.api.post(`dataset/markdown.master/action_confirm`, {
        params: {
          args: [id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    actionCancel: (id: number) => {
      return this.api.post(`dataset/markdown.master/action_cancel`, {
        params: {
          args: [id],
          kwargs: {
            context: {
              lang: this.currentLanguage,
            },
          },
        },
      })
    },
    ProductLine: {
      ...this._generateCrud('markdown.product'),
    },
    History: {
      ...this._generateCrud('markdown.product.history'),
    },
  }

  OrderMasterLine = {
    ...this._generateCrud('order.master.line'),
    orderLineBacklogSummary: async (params: SearchParams) => {
      const res = await this.api
        .post('/dataset/order.master.line/backlog_summary', {
          params: {
            args: [
              {
                ...params,
              },
            ],
            kwargs: {
              context: { lang: this.currentLanguage },
            },
          },
        })
        .then((res) => res.data)
      return res?.result
    },
  }

  LPOS = {
    Services: { ...this._generateCrud('pos.service') },
    PaymentMethods: { ...this._generateCrud('pos.payment.method') },
    FormalReceipts: { ...this._generateCrud('formal.receipt') },
  }

  SnapshotInventoryList = {
    ...this._generateCrud('monthly.summary.snapshot.stock.quant'),
    downloadExcel: ({
      headers,
      convert_fields,
      domain,
      currency_fields,
      date_fields,
      ratio_fields,
      sort,
    }: {
      headers: any[]
      convert_fields?: any
      currency_fields: string[]
      date_fields: string[]
      domain: any
      ratio_fields?: any[]
      sort: string
    }) => {
      return this.api.post(
        '/dataset/monthly.summary.snapshot.stock.quant/download_excel',
        {
          params: {
            args: [],
            kwargs: {
              headers,
              convert_fields,
              date_fields,
              currency_fields,
              ratio_fields,
              domain,
              sort,
              lang: this.currentLanguage,
            },
          },
        },
        {
          responseType: 'blob',
        },
      )
    },
  }

  WarehouseStockShelf = {
    ...this._generateCrud('warehouse.stock.shelf'),
  }

  AccountMoveList = this._generateCrud('account.move.list')

  get ATTENTION_TERM_MASTER() {
    return {
      ...this._generateCrud('attention.term.master'),
    }
  }
  get SUPPLEMENTARY_TERM_MASTER() {
    return {
      ...this._generateCrud('supplementary.term.master'),
    }
  }
  get LabelMaster() {
    return {
      Company: this._generateCrud('company.label.master'),
      Country: this._generateCrud('country.label.master'),
      PhoneNumber: this._generateCrud('phone.number.label.master'),
    }
  }
  get IR_Translation() {
    return {
      ...this._generateCrud('ir.translation'),
      getInstalledLanguage: () => {
        return this.api
          .post('/dataset/res.lang/get_installed', {
            params: {
              args: [],
              kwargs: {},
            },
          })
          .then((resp) => resp.data)
      },
      getTranslatableFields: ({
        model,
        id,
        field,
      }: {
        model: string
        id: string | number
        field: string
      }) => {
        return this.api
          .post('/dataset/ir.translation/translate_fields', {
            params: {
              args: [model, id, field],
              kwargs: {},
            },
          })
          .then((resp) => resp.data)
      },
    }
  }

  // Please place all common request like download excel, auth, etc. in this getter
  get commonRequests() {
    return {
      downloadBackgroundQueue: this._generateCrud('download.background'),
      downloadPDF: ({
        model,
        id,
        ...contextPayload
      }: {
        model: string
        id: number | number[]
        [key: string]: any
      }) => {
        return this.api
          .post(`/dataset/${model}/download_pdf`, {
            params: {
              args: [id],
              kwargs: {
                context: {
                  lang: this.currentLanguage,
                  ...contextPayload,
                },
              },
            },
          })
          .then((resp) => {
            if (
              typeof resp.data.result === 'string' &&
              resp.data.result.startsWith('http')
            ) {
              // open file url
              const link = document.createElement('a')
              link.href = resp.data.result
              document.body.appendChild(link)
              link.click()
              link.remove()
              return resp.data.result
            }
            // const url = window.URL.createObjectURL(
            //   new Blob([resp.data], { type: 'application/pdf;charset=utf-8' }),
            // )
            // const link = document.createElement('a')
            // link.href = url
            // link.setAttribute(
            //   'download',
            //   `${filename ?? `download_${Date.now()}`}.pdf`,
            // )
            // document.body.appendChild(link)
            // link.click()
            // link.remove()
            // window.URL.revokeObjectURL(url)
          })
      },
    }
  }
}

const ApiCall = new BaseApiCall()

export default ApiCall
